import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Input, Modal, Popover, Radio, message } from 'antd';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import 'videojs-youtube';
import {
  changeState,
  eduLogout,
  eduQuizSolution,
  eduVideoUpdate,
  getEdu,
  readEmail,
} from 'store/edumain';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import Loading from 'components/common/Loading';
import EduVideoPlayer from 'components/branch/edumain/EduVideoPlayer';
import EduPdfViewer from 'components/branch/edumain/EduPdfViewer';

interface PlayType {
  playStep: number;
  playTime: number;
  runningTime: number;
  totalRunningTime: number;
}

const initialPlay = {
  playStep: 1,
  playTime: 0,
  runningTime: 1,
  totalRunningTime: 0,
};

function Eduplay() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openEduList, setOpenEduList]: any = useState([]);
  const [videoOptions, setVideoOptions]: any = useState({});
  const [playData, setPlayData] = useState<PlayType>({ ...initialPlay });
  const [quizScreen, setQuizScreen] = useState(false);
  const [answer, setAnswer]: any = useState({});
  const [quizResultScreen, setQuizResultScreen] = useState(false);
  const [quizResult, setQuizResult]: any = useState({});

  const {
    serviceNo,
    userData,
    eduTitle,
    eduList,
    eduDataByEduNo,
    selectedEduNo,
    japanMode,
  } = useSelector((state: any) => {
    return {
      serviceNo: state.edumain.serviceNo,
      userData: state.edumain.userData,
      eduTitle: state.edumain.eduTitle,
      eduList: state.edumain.eduList,
      eduDataByEduNo: state.edumain.eduDataByEduNo,
      selectedEduNo: state.edumain.selectedEduNo,
      japanMode: state.edumain.japanMode,
    };
  }, shallowEqual);

  useEffect(() => {
    if (userData) {
      if (userData?.isActive) {
        onGetEdu();
      } else {
        history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (Object.keys(eduDataByEduNo).length > 0) {
      const { playStep, playTime, attachInfo, quizSolutionData, isEndEach } = eduDataByEduNo[selectedEduNo];

      if (Object.keys(quizSolutionData).length > 0) {
        const initialAnswer: any = {};
        Object.keys(quizSolutionData).forEach((key: any) => {
          initialAnswer[key] = quizSolutionData[key].quizSolution;
        });
        setAnswer(initialAnswer);
      }

      setPlayData({
        playStep: playStep || 1,
        playTime: playTime,
        runningTime: isEndEach ? playTime : 1,
        totalRunningTime: attachInfo.length,
      });

      // 비디오 리스트 설정
      const options: any = {};
      attachInfo.forEach((attachItem: any) => {
        const videoList: Array<any> = [];
        attachItem?.fileInfo?.forEach((file: any) => {
          videoList.push({
            sources: [
              {
                src: file.filePath,
                type: attachItem?.contentType === 2 ? 'video/youtube' : 'video/mp4',
              },
            ],
            // poster: attachItem.thumbNail,
          });
        });
        options[attachItem.eduAttachNo] = videoList;
      });
      setVideoOptions(options);
    }
  }, [selectedEduNo]);

  // 교육 데이터 업데이트
  const updatePlayData = (updateData: any) => {
    setPlayData({ ...playData, ...updateData });
  };

  // 선택한 교육의 전체 컨텐츠 정보 리스트
  const attachInfo = useMemo(() => {
    if (Object.keys(eduDataByEduNo).length > 0) {
      return eduDataByEduNo[selectedEduNo].attachInfo;
    }
    return [];
  }, [eduDataByEduNo, selectedEduNo]);

  // 현재 시청하고 있는 교육 컨텐츠 정보
  const currentAttachInfo: { [key: string]: any; } = useMemo(() => {
    const index = playData.playStep <= 1 ? 0 : playData.playStep - 1;
    return attachInfo[index];
  }, [attachInfo, JSON.stringify(playData), selectedEduNo]);

  // 시청하고 있는 컨텐츠의 퀴즈 정보만 추출
  const getCurrentQuiz = () => {
    return eduDataByEduNo[selectedEduNo]?.quizData?.filter(
      (quiz: any) => quiz.eduAttachNo === currentAttachInfo?.eduAttachNo,
    );
  };

  // 로그아웃
  const onLogout = async () => {
    try {
      await dispatch(eduLogout());
      history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
    } catch (error) {
      console.log('onLogout', error);
    }
  };

  // 교육 리스트 조회
  const onGetEdu = async () => {
    setLoading(true);
    try {
      const params = {
        eduTargetNo: userData.eduTargetNo,
      };
      const response: any = await dispatch(getEdu(params));
      setLoading(false);
      if (response?.data?.list?.length > 0) {
        const { list } = response.data;
        list.forEach((item: any) => {
          if (!item.readDate) {
            onReadEmail(item.eduTargetLinkNo);
          }
        });
      } else {
        message.warning(
          formatMessage({ id: 'Edu_80', defaultMessage: '진행중인 교육이 없습니다.' }),
          2,
        );
        history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
      }
    } catch (error) {
      console.log('onGetEdu', error);
    }
  };

  // 교육 메일 열람 처리
  const onReadEmail = async (eduTargetLinkNo: number) => {
    try {
      const params = {
        eduTargetLinkNo: eduTargetLinkNo,
      };
      await dispatch(readEmail(params));
    } catch (error) {
      console.log('onReadEmail', error);
    }
  };

  // 교육 선택 시
  const onSelectEdu = (eduNo: number) => {
    setAnswer({});
    setQuizScreen(false);
    setQuizResultScreen(false);
    setQuizResult({});
    dispatch(changeState({ key: 'selectedEduNo', value: eduNo }));
  };

  // 교육 리스트 열기/닫기
  const toggleOpen = (eduInfo: any) => {
    const openList = [...openEduList];
    const existIndex = openList.indexOf(eduInfo.eduNo);
    if (existIndex > -1) {
      openList.splice(existIndex, 1);
    } else {
      openList.push(eduInfo.eduNo);
    }

    setOpenEduList(openList);
  };

  // 선택한 교육 상세 진행 상태 라벨
  const eduStatusLabel = useMemo(() => {
    // if (!playData) return <></>;
    if (!eduDataByEduNo[selectedEduNo]) return <></>;
    const { eduStatus } = eduDataByEduNo[selectedEduNo];

    let status = 'wait';
    let message = formatMessage({ id: 'Status_17', defaultMessage: '대기' });
    if (eduStatus === 1) {
      status = 'ing';
      message = formatMessage({ id: 'Status_8', defaultMessage: '진행중' });
    } else if (eduStatus === 2) {
      status = 'finish';
      message = formatMessage({ id: 'Status_16', defaultMessage: '완료' });
    }
    return <span className={`edu-status ${status}`}>{message}</span>;
  }, [JSON.stringify(eduDataByEduNo[selectedEduNo])]);

  const getTabListItem = (contentNo: any, contentName: any) => {
    if (!playData) return <></>;
    const { playStep } = playData;
    const { eduStatus } = eduDataByEduNo;

    return attachInfo?.map((content: any, index: number) => {
      let active = '';
      let status = 'before';
      let message = formatMessage({ id: 'Status_18', defaultMessage: '학습 전' });
      const step = playStep === 0 ? 1 : playStep;

      if (eduStatus === 2) {
        if (step === index + 1) {
          active = 'on';
          status = 'ing';
          message = formatMessage({ id: 'Status_19', defaultMessage: '학습 중' });
        } else {
          status = 'finish';
          message = formatMessage({ id: 'Status_20', defaultMessage: '학습 완료' });
        }
      } else if (step === index + 1) {
        active = 'on';
        status = 'ing';
        message = formatMessage({ id: 'Status_19', defaultMessage: '학습 중' });
      } else if (step > index + 1) {
        status = 'finish';
        message = formatMessage({ id: 'Status_20', defaultMessage: '학습 완료' });
      }

      return (
        <Popover
          placement="bottomLeft"
          content={
            <div>
              {`${index + 1}. ${content[contentName]}`}
              <div className={`edu-play-tab-label ${status} ${japanMode ? 'green-theme' : ''}`}>
                {message}
              </div>
            </div>
          }
          key={content[contentNo]}
        >
          <li className={`tab-item ${active}`}>
            {active
              ? content[contentName]
              : `${formatMessage({
                id: 'License_37',
                defaultMessage: '컨텐츠',
              })}${index + 1}`}
          </li>
        </Popover>
      );
    });
  };

  const eduContentTabList = getTabListItem('eduAttachNo', 'attachName');

  // 이전 컨텐츠 이동
  const movePrevContents = () => {
    if (eduDataByEduNo[selectedEduNo]?.eduStatus === 2) {
      setQuizScreen(false);
      setQuizResultScreen(false);
      updatePlayData({
        playStep: playData.playStep <= 1 ? 1 : playData.playStep - 1,
      });
    }
  };

  // 다음 컨텐츠 이동
  const moveNextContents = async (submitAnswer: boolean) => {
    const { quizData, eduStatus } = eduDataByEduNo[selectedEduNo];
    const { playStep, playTime, runningTime, totalRunningTime } = playData;

    // 재생 시간과 총 시간이 같지 않으면 return 이나 전체 수강시 자유로운 이동 가능
    if (eduStatus !== 2 && (!attachInfo || (playTime !== runningTime && !quizResultScreen))) {
      return;
    }

    // 선택한 교육에 퀴즈가 존재할 경우(여러 컨텐츠 중 하나의 컨텐츠에만 퀴즈가 존재해도 true)
    const existQuiz = quizData?.length > 0;
    // 모든 컨텐츠 진행 완료 여부
    const playComplete = playStep === attachInfo.length && (playTime === runningTime || eduStatus === 2);
    // 현재 컨텐츠에 퀴즈가 존재할 경우
    const currentExistQuiz = getCurrentQuiz().length > 0;
    const currentAnswer: any = {};
    if (currentExistQuiz) {
      getCurrentQuiz().forEach((item: any) => {
        if (item.quizNo in answer) {
          currentAnswer[item.quizNo] = true;
        }
      });
    }

    // 현재 컨텐츠 퀴즈 완료 여부
    const currentQuizComplete = currentExistQuiz ? Object.keys(currentAnswer).length === getCurrentQuiz().length && submitAnswer : true;
    // 퀴즈 완료 여부
    const quizComplete = currentQuizComplete || quizResultScreen || eduStatus === 2;
    // 넘어갈 단계
    const nextStep = playStep === attachInfo.length ? playStep : playStep + 1;

    try {
      const params = {
        eduNo: selectedEduNo,
        eduTargetNo: userData.eduTargetNo,
        eduAttachNo: currentAttachInfo.eduAttachNo,
        playStep: nextStep,
        playTime: 0,
        isEndEdu: playComplete && quizComplete ? 'true' : 'false',
        isEndEach: playTime === runningTime ? 1 : 0
      };

      if (playStep !== nextStep) {
        setQuizScreen(false);
      }

      // 퀴즈 화면 보이기 (api 호출X)
      if (currentExistQuiz && (!quizResultScreen && !quizScreen || !quizComplete)) {
        setQuizScreen(true);
        // 1페이지 PDF 예외 처리
        if (currentAttachInfo.contentType === 3 && runningTime === 1) {
          params.playStep = playStep;
          params.playTime = 1;
          params.isEndEach = 1;
        } else {
          return;
        }
      } else if (existQuiz && playComplete && quizComplete && !quizResultScreen) {
        // 퀴즈 결과 화면 보이기 (api 호출X)
        setQuizScreen(false);
        setQuizResultScreen(true);
      } else if (playComplete && quizComplete || (eduStatus === 2 && playStep === totalRunningTime)) {
        // 모든 교육 컨텐츠 및 퀴즈 풀기 완료 시 완료 안내 표시
        Modal.info({
          title: formatMessage({ id: 'Edu_121', defaultMessage: '수강이 완료되었습니다.' }),
          className: `edu-info-modal ${japanMode ? 'green-theme' : ''}`,
          okText: formatMessage({ id: 'Button_4', defaultMessage: '확 인' }),
        });
      }

      // 완료된 교육
      if (eduStatus === 2) {
        updatePlayData({
          playStep: runningTime === 1 ? playStep : nextStep,
          playTime: 0,
          runningTime: 0,
        });
        return;
      }

      await dispatch(eduVideoUpdate(params));

      updatePlayData({
        playStep: runningTime === 1 && !currentQuizComplete ? playStep : nextStep,
        playTime: runningTime === 1 ? playTime : 0,
        runningTime: 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAnswer = (e: any, quiz: any) => {
    const { quizNo, quizType } = quiz;
    const quizValue = quizType === 3 ? e.target.value : parseInt(e.target.value, 10);

    setAnswer({ ...answer, [quizNo]: quizValue });
  };

  // 퀴즈 푼 후 제출 버튼 이벤트
  const onSubmitAnswer = async () => {
    try {
      const quizNoList: Array<any> = [];
      const quizSolutionList: Array<any> = [];
      Object.keys(answer).forEach((key) => {
        quizNoList.push(key);
        quizSolutionList.push(answer[key]?.toString());
      });

      const params = {
        eduNo: selectedEduNo,
        eduTargetNo: userData.eduTargetNo,
        eduAttachNo: currentAttachInfo.eduAttachNo,
        quizNo: JSON.stringify(quizNoList),
        quizSolution: JSON.stringify(quizSolutionList),
      };
      const response: any = await dispatch(eduQuizSolution(params));
      setQuizResult(response?.data?.list?.[0]?.result);
      setQuizScreen(false);
      // 퀴즈 풀고 나서 현재 진행 상태 저장
      moveNextContents(true);
      setAnswer({});
    } catch (error) {
      console.log(error);
    }
  };

  // 퀴즈 유형별 답변 컴포넌트
  const answerItem = (quiz: any, solutionValue: string) => {
    const { quizNo, quizType, extraInfo } = quiz;

    if (quizType === 1) {
      // ox
      return (
        <Radio.Group onChange={(e: any) => handleAnswer(e, quiz)} defaultValue={solutionValue}>
          <Radio value="1">O</Radio>
          <Radio value="2">X</Radio>
        </Radio.Group>
      );
    }
    if (quizType === 2) {
      // 객관식
      return (
        <Radio.Group onChange={(e: any) => handleAnswer(e, quiz)} defaultValue={solutionValue}>
          {extraInfo?.map((info: any) => {
            const exampleNo = Object.keys(info)[0];
            const exampleText = Object.values(info);
            return (
              <Radio key={exampleNo} value={exampleNo}>
                {exampleText}
              </Radio>
            );
          })}
        </Radio.Group>
      );
    }
    if (quizType === 3) {
      // 단답형
      return <Input onChange={(e: any) => handleAnswer(e, quiz)} defaultValue={solutionValue} />;
    }
    return '-';
  };

  // 퀴즈 화면 컴포넌트
  const quizAnswerComponent: any = useMemo(() => {
    // 교육 컨텐츠 번호 순서대로 저장
    const sortedAttachNoList = eduDataByEduNo[selectedEduNo]?.attachInfo?.map((info: any) => info.eduAttachNo);
    // 전체 기준으로 퀴즈 순서 저장한 데이터
    const indexedData: any = [];
    let count = 1;
    sortedAttachNoList?.forEach((attachNo: number) => {
      eduDataByEduNo[selectedEduNo]?.quizData.forEach((data: any) => {
        if (data.eduAttachNo === attachNo) {
          indexedData.push({ quizIndex: count, ...data });
          count += 1;
        }
      });
    });
    // 순서가 저장된 현재 퀴즈 데이터
    const currentQuiz = indexedData?.filter((data: any) => data.eduAttachNo === currentAttachInfo?.eduAttachNo);

    return (
      <>
        <div className="quiz-list-header">
          <div className="no-column">No</div>
          <div className="question-column">{formatMessage({ id: 'Edu_91', defaultMessage: '질문' })}</div>
          <div className="answer-column">{formatMessage({ id: 'Edu_102', defaultMessage: '답변' })}</div>
        </div>
        <div className="quiz-list-body">
          {currentQuiz?.map((quiz: any) => {
            const solutionData = eduDataByEduNo[selectedEduNo]?.quizSolutionData?.[quiz.quizNo];
            const solutionValue = solutionData?.quizSolution || '';

            return (
              <ul className={`quiz-list-row ${solutionValue && eduDataByEduNo[selectedEduNo]?.eduStatus === 2 ? 'non-click' : ''}`} key={quiz.quizNo}>
                <li className="no-column">{quiz.quizIndex}</li>
                <li className="question-column">{quiz.quizContent}</li>
                <li className="answer-column">{answerItem(quiz, solutionValue)}</li>
              </ul>
            );
          })}
        </div>
      </>
    );
  }, [JSON.stringify(eduDataByEduNo[selectedEduNo]), selectedEduNo, currentAttachInfo, answerItem]);

  // 모든 컨텐츠 끝난 후 퀴즈 및 해설 컴포넌트
  const quizResultComponent: any = useMemo(() => {
    if (!eduDataByEduNo[selectedEduNo]) return <></>;
    const resultData = Object.keys(quizResult)?.length ? quizResult : eduDataByEduNo[selectedEduNo].quizSolutionData;

    // 컨텐츠별 퀴즈 분리
    const quizByContents: any = {};
    eduDataByEduNo[selectedEduNo]?.quizData?.forEach((item: any) => {
      if (quizByContents[item.eduAttachNo]) {
        quizByContents[item.eduAttachNo].push(item);
      } else {
        quizByContents[item.eduAttachNo] = [item];
      }
    });

    // 컨텐츠별 문제/정답/해설 출력
    const resultComponent: any = [];
    let quizIndex = 0;

    eduDataByEduNo[selectedEduNo]?.attachInfo?.forEach((attachItem: any) => {
      if (quizByContents[attachItem.eduAttachNo]) {
        // 컨텐츠명 및 컬럼 타이틀
        resultComponent.push(
          <>
            <div className="contents-name">{attachItem?.attachName}</div>
            <div className="quiz-list-header">
              <div className="no-column">No</div>
              <div className="question-column">{formatMessage({ id: 'Edu_91', defaultMessage: '질문' })}</div>
              <div className="answer-column">{formatMessage({ id: 'Edu_35', defaultMessage: '정답' })}</div>
            </div>
          </>);
      }

      let answerComponent: any = null;
      Object.keys(quizByContents).forEach((key: any) => {
        if (attachItem.eduAttachNo.toString() === key) {
          // 퀴즈 내용
          resultComponent.push(quizByContents[key].map((quiz: any) => {
            if (!resultData[quiz.quizNo]) return <></>;
            quizIndex += 1;

            const { quizType, extraInfo } = quiz;
            const { quizSolution, quizCorrect, result } = resultData[quiz.quizNo];
            // 퀴즈 정답(correct) 항목은 녹색으로 표시, 오답(fail) 항목은 빨간색으로 표시
            // ox
            if (quizType === 1) {
              answerComponent = (
                <Radio.Group value={quizSolution} >
                  <Radio value="1" className={quizCorrect === "1" ? 'correct' : 'fail'}>O</Radio>
                  <Radio value="2" className={quizCorrect === "2" ? 'correct' : 'fail'}>X</Radio>
                </Radio.Group>
              );
            }
            // 객관식
            if (quizType === 2) {
              answerComponent = (
                <Radio.Group value={quizSolution}>
                  {extraInfo?.map((info: any) => {
                    const exampleNo = Object.keys(info)[0];
                    const exampleText = Object.values(info);
                    return (
                      <Radio key={exampleNo} value={exampleNo} className={quizCorrect === exampleNo ? 'correct' : 'fail'}>
                        {exampleText}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              );
            }
            // 단답형
            if (quizType === 3) {
              answerComponent = <>
                <Radio.Group value={quizSolution}>
                  <Radio value={quizSolution} className={result}>{quizSolution}</Radio>
                  {result === 'fail' && <Radio value={quizCorrect} className="correct">{quizCorrect}</Radio>}
                </Radio.Group>
              </>;
            }

            return <>
              <div className="quiz-list-body result">
                <ul className="quiz-list-row non-click" key={quiz.quizNo}>
                  <li className="no-column">{quizIndex}</li>
                  <li className="question-column">{quiz.quizContent}</li>
                  <li className="answer-column">{answerComponent}</li>
                </ul>
                <ul className="explain-column">
                  <li>{formatMessage({ id: 'Edu_92', defaultMessage: '문제 해설' })}</li>
                  <li>{quiz.quizExplain}</li>
                </ul></div>
            </>;
          }));
        }
      });
    });
    return resultComponent;
  }, [quizResult, selectedEduNo, JSON.stringify(eduDataByEduNo)]);

  const onReadyPlayerEvent = (player: any) => {
    let curPlayTime = playData.playTime;
    let hasPlayed = false;

    // 기록되었던 시간부터 재생
    player.on('play', function () {
      // 비디오 준비됐을 때 한 번만 기본값 설정
      if (!hasPlayed) {
        hasPlayed = true;
        player.currentTime(curPlayTime);
      }
    });

    player.on('timeupdate', function () {
      if (player.currentTime() > curPlayTime + 10 || player.ended()) {
        updatePlayData({
          playTime: player.currentTime(),
          runningTime: player.ended() ? player.currentTime() : playData.runningTime,
        });
        curPlayTime = player.currentTime();

        dispatch(eduVideoUpdate({
          eduNo: selectedEduNo,
          eduTargetNo: userData.eduTargetNo,
          eduAttachNo: currentAttachInfo.eduAttachNo,
          playStep: playData.playStep,
          playTime: player.currentTime(),
          isEndEdu: 'false',
          isEndEach: player.currentTime() === (player.ended() ? player.currentTime() : playData.runningTime) ? 1 : 0
        }));

        if (player.ended()) {
          player.off('timeupdate');
        }
      }
    });
  };

  const eduContentComponent: any = useMemo(() => {
    if (!currentAttachInfo || Object.keys(videoOptions).length < 1) return <></>;

    const { contentType } = currentAttachInfo;
    if (contentType === 3) {
      return (
        <EduPdfViewer
          playData={playData}
          updatePlayData={updatePlayData}
          filePath={currentAttachInfo?.fileInfo[0]?.filePath}
        />
      );
    }

    return (
      <div className="video-player">
        <EduVideoPlayer
          curVideoData={playData}
          videoOption={videoOptions[currentAttachInfo.eduAttachNo]}
          onReadyPlayerEvent={onReadyPlayerEvent}
        />
      </div>
    );
  }, [JSON.stringify(currentAttachInfo), JSON.stringify(videoOptions), JSON.stringify(playData)]);

  return (
    <div className="edu-layout">
      <Loading loading={loading} />

      <div className="edu-play-left">
        <div className="bg-effect">
          <div className="effect-1" />
          <div className="effect-2" />
          <div className="effect-3" />
        </div>

        <div className="list-box">
          <div className="list-top">{formatMessage({ id: 'Edu_107', defaultMessage: 'List' })}</div>
          {Object.keys(eduDataByEduNo)?.length > 0 && eduList ? (
            eduList?.map((eduInfo: any) => {
              const item = eduDataByEduNo[eduInfo.eduNo];

              return (
                <div
                  key={item.eduNo}
                  className={`list-item ${item.eduNo === selectedEduNo ? 'active' : ''}`}
                  onClick={() => onSelectEdu(item.eduNo)}
                  aria-hidden="true"
                >
                  <div className="list-item-title">
                    {item.eduStatus === 2 && (
                      <span className="complete">
                        {formatMessage({ id: 'Status_16', defaultMessage: '완료' })}
                      </span>
                    )}
                    {item.eduName}
                  </div>
                  <dl>
                    <dt className="list-label">
                      {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
                    </dt>
                    <dd className="list-value">{`${timeFormatFromUTCEpoch(
                      item.startEpoch,
                      3,
                    )} ~ ${timeFormatFromUTCEpoch(item.endEpoch, 3)}`}</dd>
                  </dl>

                  <dl
                    className={`list-video-box ${openEduList.includes(item.eduNo) ? 'active' : ''}`}
                  >
                    <dt className="list-label">
                      {formatMessage({ id: 'Edu_62', defaultMessage: '컨텐츠 목록' })}
                    </dt>
                    <ol className="list-video">
                      {item.attachInfo?.map((attach: any) => {
                        return (
                          <li key={attach.eduAttachNo} className="video-text">
                            {attach.attachName}
                          </li>
                        );
                      })}
                    </ol>
                  </dl>

                  <div className="edu-progress-bar">
                    <div
                      className="progress-percent"
                      style={{ width: `${item.eduStatus === 2 ? 100 : item.eduProgress}%` }}
                    />
                  </div>

                  <div
                    className={`edu-list-arrow ${openEduList.includes(item.eduNo) ? 'open' : ''}`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      toggleOpen(item);
                    }}
                    aria-hidden="true"
                  >
                    {item.eduNo === selectedEduNo ? (
                      <img src="/img/edu/active_arrbtn.png" alt="arrow" />
                    ) : (
                      <img src="/img/edu/none_arrbtn.png" alt="arrow" />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* 우측 영역 */}
      <div className="edu-play-right">
        <div className="edu-play-header">
          <div className="edu-play-title">
            {eduTitle ||
              formatMessage(
                { id: 'Edu_71', defaultMessage: '{service} 악성 메일 대응 교육' },
                { service: Option.serviceName },
              )}
          </div>
          <Popover
            overlayClassName="edu-play-popover"
            placement="bottomRight"
            trigger="click"
            content={
              <>
                <div className="edu-my-box">
                  {japanMode ? (
                    <div className="box-bg-effect">
                      <div className="effect-1" />
                      <div className="effect-2" />
                    </div>
                  ) : (
                    <img src="/img/edu/mybox_inner.png" alt="bg" />
                  )}
                  <div className="edu-target-box">
                    <img src="/img/edu/my_i.png" alt="bg" />
                    <div className="edu-target-info">
                      <div className="edu-target-name">{userData?.eduTargetName}</div>
                      <div className="edu-target-id">{userData?.eduTargetId}</div>
                    </div>
                  </div>
                </div>
                <div className="logout-btn">
                  <button onClick={onLogout} type="button">
                    <img src="/img/edu/logout_i.png" alt="logout" />
                    {formatMessage({ id: 'AlreadyLogin_4', defaultMessage: '로그아웃' })}
                  </button>
                </div>
              </>
            }
          >
            <div className="my-info">
              <img src="/img/edu/my_i.png" alt="my" />
              <span className="name">{userData?.eduTargetName}</span>
            </div>
          </Popover>
        </div>

        {/* 상세 */}
        <div className="video-info">
          <span className="video-name">{eduDataByEduNo[selectedEduNo]?.eduName}</span>
          {eduStatusLabel}
          <div className="video-summary">
            <span className="summary-item">
              <span className="summary-label">
                {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
              </span>
              <span className="summary-value period">
                {`${timeFormatFromUTCEpoch(
                  eduDataByEduNo[selectedEduNo]?.startEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(eduDataByEduNo[selectedEduNo]?.endEpoch, 3)}`}
              </span>
            </span>
            <div className="summary-item percent">
              <span className="summary-label">
                {formatMessage({ id: 'Template_28', defaultMessage: '진행률' })}
              </span>
              <div className="edu-progress-bar">
                <div
                  className="progress-percent"
                  style={{
                    width: `${eduDataByEduNo[selectedEduNo]?.eduProgress}%`,
                  }}
                />
              </div>
              <span className="percent-text">{`${eduDataByEduNo[selectedEduNo]?.eduProgress}%`}</span>
            </div>
          </div>
        </div>

        <div>
          {/* 컨텐츠 목록 */}
          <div className="video-tab">
            <ol className="tab-list">
              {eduDataByEduNo[selectedEduNo]?.fileInfo?.map((file: any, index: number) => {
                let active = '';
                let status = 'before';
                let message = formatMessage({ id: 'Status_18', defaultMessage: '학습 전' });
                const step = playData.playStep === 0 ? 1 : playData.playStep;

                if (eduDataByEduNo[selectedEduNo]?.eduStatus === 2) {
                  if (step === index + 1) {
                    active = 'on';
                    status = 'ing';
                    message = formatMessage({ id: 'Status_19', defaultMessage: '학습 중' });
                  } else {
                    status = 'finish';
                    message = formatMessage({ id: 'Status_20', defaultMessage: '학습 완료' });
                  }
                } else if (step === index + 1) {
                  active = 'on';
                  status = 'ing';
                  message = formatMessage({ id: 'Status_19', defaultMessage: '학습 중' });
                } else if (step > index + 1) {
                  status = 'finish';
                  message = formatMessage({ id: 'Status_20', defaultMessage: '학습 완료' });
                }

                return (
                  <Popover
                    placement="bottomLeft"
                    content={
                      <div>
                        {`${index + 1}. ${file.fileName}`}
                        <div className={`edu-play-tab-label ${status}`}>{message}</div>
                      </div>
                    }
                    key={file.fileNo}
                  >
                    <li className={`tab-item ${active}`}>
                      {active
                        ? file.fileName
                        : `${formatMessage({
                          id: 'Edu_78',
                          defaultMessage: '영상',
                        })}${index + 1}`}
                    </li>
                  </Popover>
                );
              })}
            </ol>
          </div>

          {/* 컨텐츠 이동 */}
          <div className="video-tab">
            <ol className="tab-list">{eduContentTabList}</ol>
            <div className="move-contents-button-box">
              {formatMessage({ id: 'Edu_101', defaultMessage: '컨텐츠 이동' })}
              <button className="move-contents-button" type="button" onClick={movePrevContents}>
                <LeftCircleFilled />
              </button>
              <span className="move-contents-text">
                {`${playData.playStep || 1} / ${attachInfo?.length}`}
              </span>
              <button className="move-contents-button" type="button" onClick={() => moveNextContents(false)}>
                <RightCircleFilled />
              </button>
            </div>
          </div>
        </div>

        {!quizScreen && !quizResultScreen ? eduContentComponent : <></>}

        {/* Quiz */}
        {quizScreen && (
          <div className="edu-quiz-screen">
            <button
              className="submit-answer-button"
              type="button"
              onClick={onSubmitAnswer}
              disabled={Object.keys(answer).length < getCurrentQuiz().length || eduDataByEduNo[selectedEduNo]?.eduStatus === 2}
            >
              {formatMessage({ id: 'Edu_103', defaultMessage: '제출' })}
            </button>
            <div className="quiz-screen-table" style={{ overflowWrap: 'anywhere' }} >
              {getCurrentQuiz().length > 0 ? quizAnswerComponent : <></>}
            </div>
          </div>
        )}
        {quizResultScreen && (
          <div className="edu-quiz-screen">
            <div className="quiz-screen-table" style={{ overflowWrap: 'anywhere' }} >
              {quizResultComponent}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Eduplay;
