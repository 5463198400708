import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import fileDownload from 'js-file-download';
import {
  getEduTarget,
  getEduList,
  changeState,
  deleteEdu,
  setInitial,
  downloadReport,
  eduResend,
} from 'store/edu';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import ContentHeader from 'components/common/ContentHeader';
import Dimmer from 'components/common/Dimmer';
import ModalTemplate from 'components/common/ModalTemplate';
import ContentList from 'components/common/ContentList';
import SearchFilter from 'components/common/SearchFilter';
import EduSidebar from 'components/branch/edu/EduSidebar';
import EduItem from 'components/branch/edu/EduItem';
import EduDetail from 'components/branch/edu/EduDetail';
import './Eduresult.scss';

export const authLevel = 1;
function Eduresult() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const eduSummary = useSelector((state: any) => {
    return {
      data: state.edu.eduSummary.data,
      dataByEduNo: state.edu.eduSummary.dataByEduNo,
      param: state.edu.eduSummary.param,
      needReload: state.edu.eduSummary.needReload,
      selectedEduNo: state.edu.eduSummary.selectedEduNo,
    };
  }, shallowEqual);

  const eduList = useSelector((state: any) => {
    return {
      data: state.edu.eduList.data,
      dataByEduNo: state.edu.eduList.dataByEduNo,
      param: state.edu.eduList.param,
      needReload: state.edu.eduList.needReload,
      page: state.edu.eduList.page,
      totalPage: state.edu.eduList.totalPage,
      totalItem: state.edu.eduList.totalItem,
      selectType: state.edu.eduList.selectType,
      selectedList: state.edu.eduList.selectedList,
    };
  }, shallowEqual);

  const eduTarget = useSelector((state: any) => {
    return {
      data: state.edu.eduTarget.data,
      dataByEduNo: state.edu.eduTarget.dataByEduNo,
      param: state.edu.eduTarget.param,
      needReload: state.edu.eduTarget.needReload,
      page: state.edu.eduTarget.page,
      totalPage: state.edu.eduTarget.totalPage,
      totalItem: state.edu.eduTarget.totalItem,
      selectType: state.edu.eduTarget.selectType,
      selectedList: state.edu.eduTarget.selectedList,
    };
  }, shallowEqual);

  const userData = useSelector((state: any) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState('');

  useEffect(() => {
    // 다른 탭 이동 시 교육결과 스토어 초기화
    return () => {
      dispatch(setInitial());
    };
  }, []);

  useEffect(() => {
    if (eduSummary.selectedEduNo === 'all') {
      onGetEduList(true);
    } else {
      onGetEduTarget(true);
    }
  }, [eduSummary.selectedEduNo, eduSummary.needReload, eduTarget.param, eduList.param]);

  // 교육 리스트 새로고침
  const onEduRefresh = async () => {
    setLoading(true);

    try {
      const params = {
        filter: {},
        offset: 0,
        limit: eduSummary.selectedEduNo === 'all' ? eduList.param.limit : eduTarget.param.limit,
        refresh: true,
      };

      // 교육 대상자 param 변경 시
      await dispatch(
        changeState({
          key: eduSummary.selectedEduNo === 'all' ? 'eduList' : 'eduTarget',
          value: { param: params },
        }),
      );
      setLoading(false);
    } catch (error) {
      console.log('Edu onEduRefresh', error);
    }
  };

  // 교육 리스트 조회
  const onGetEduList = async (refresh = false) => {
    setLoading(true);

    try {
      const params: any = {
        eduNo: eduSummary.selectedEduNo,
        filter: JSON.stringify(eduList.param.filter),
        sort: JSON.stringify(eduList.param.sort),
        offset: 0,
        limit: eduList.param.limit,
      };

      if (Object.keys(eduSummary?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(eduSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = eduList.param.limit * eduList.page;
      }

      await dispatch(getEduList(params));
      setLoading(false);
    } catch (error) {
      console.log('Edu onGetEduList', error);
    }
  };

  // 교육 대상자 조회
  const onGetEduTarget = async (refresh = false) => {
    setLoading(true);

    try {
      const params: any = {
        eduNo: eduSummary.selectedEduNo,
        filter: JSON.stringify(eduTarget.param.filter),
        sort: JSON.stringify(eduTarget.param.sort),
        offset: 0,
        limit: eduTarget.param.limit,
      };

      if (Object.keys(eduSummary?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(eduSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = eduTarget.param.limit * eduTarget.page;
      }

      await dispatch(getEduTarget(params));
      setLoading(false);
    } catch (error) {
      console.log('Edu onGetEduTarget', error);
    }
  };

  // 교육 선택
  const onSelectItem = useCallback(
    (type: string, no: number) => {
      const store = eduSummary.selectedEduNo === 'all' ? eduList : eduTarget;
      const storeText = eduSummary.selectedEduNo === 'all' ? 'eduList' : 'eduTarget';
      // 선택 타입 변경 시 선택 리스트 초기화
      const selectList = type !== store.selectType ? [] : [...store.selectedList];

      if (type === 'check') {
        if (selectList.includes(no)) {
          selectList.splice(selectList.indexOf(no), 1);
        } else {
          selectList.push(no);
        }
        dispatch(
          changeState({
            key: storeText,
            value: { selectedList: selectList, selectType: 'check' },
          }),
        );
      } else {
        dispatch(
          changeState({
            key: storeText,
            value: { selectedList: selectList.includes(no) ? [] : [no], selectType: 'select' },
          }),
        );
      }
    },
    [
      eduSummary.selectedEduNo,
      JSON.stringify(eduTarget.selectedList),
      JSON.stringify(eduList.selectedList),
    ],
  );

  // 교육 삭제
  const onDeleteEdu = async () => {
    setLoading(true);
    try {
      const param: any = {
        serviceNo: userData.serviceNo,
        eduNoArray: JSON.stringify(eduList.selectedList),
      };
      const response: any = await dispatch(deleteEdu(param));
      setLoading(false);
      if (!response.data.error) {
        setModal('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 엑셀 파일 다운로드
  const downloadExcel = async (params: any, reportName: string) => {
    try {
      const response: any = await dispatch(downloadReport(params));
      if (response?.data) {
        fileDownload(response.data, reportName);
      }
    } catch (error) {
      console.log('Edu downloadExcel', error);
    }
  };

  // 교육 메일 재전송
  const onResend = async () => {
    setLoading(true);
    const eduTargetNoList = eduTarget.selectedList.map((targetNo: number) => {
      return eduTarget.dataByEduNo[targetNo].eduTargetNo;
    });

    try {
      const params = {
        eduNo: eduSummary.dataByEduNo[eduSummary.selectedEduNo].eduNo,
        targetNoArray: JSON.stringify(eduTargetNoList),
      };
      await dispatch(eduResend(params));
      setLoading(false);
      setModal('');
    } catch (error) {
      console.log('onResend', error);
    }
  };

  // 교육 보고서 다운로드 클릭 이벤트
  const onDownloadReport = () => {
    setLoading(true);

    try {
      if (eduSummary.selectedEduNo === 'all') {
        for (let i = 0; i < eduList.selectedList.length; i += 1) {
          const reportName = `${
            eduSummary.dataByEduNo[eduList.selectedList[i]]?.eduName
          }_${formatMessage({
            id: 'Edu_84',
            defaultMessage: '교육 보고서',
          })}.xlsx`;
          downloadExcel({ eduNo: eduList.selectedList[i] }, reportName);
        }
      } else {
        const reportName = `${
          eduSummary.dataByEduNo[eduSummary.selectedEduNo]?.eduName
        }_${formatMessage({
          id: 'Edu_84',
          defaultMessage: '교육 보고서',
        })}.xlsx`;
        downloadExcel({ eduNo: eduSummary.selectedEduNo }, reportName);
      }

      setLoading(false);
    } catch (error) {
      console.log('Edu onDownloadReport', error);
    }
  };

  // 전체 교육 선택 여부
  const isAllEdu = useMemo(() => eduSummary.selectedEduNo === 'all', [eduSummary.selectedEduNo]);

  // 선택한 교육 정보
  const currentEdu = useMemo(() => {
    const copyList = [...eduList.selectedList];
    return eduSummary.selectedEduNo !== 'all'
      ? eduSummary.dataByEduNo[eduSummary.selectedEduNo]
      : eduList.dataByEduNo[copyList.pop()];
  }, [eduSummary.dataByEduNo, eduSummary.selectedEduNo, eduList.dataByEduNo, eduList.selectedList]);

  // 상단 제목
  const headerTitle = useMemo(
    () =>
      isAllEdu ? formatMessage({ id: 'Edu_19', defaultMessage: '전체 교육' }) : currentEdu?.eduName,
    [isAllEdu, currentEdu],
  );

  // 상단 부제목
  const headerSubTitle = useMemo(
    () =>
      isAllEdu
        ? formatMessage(
            { id: 'Edu_22', defaultMessage: '총 교육: {count}개' },
            { count: eduSummary.data.length > 0 ? eduSummary.data[0].rnum : 0 },
          )
        : ` ${formatMessage({
            id: 'Target_12',
            defaultMessage: '총 대상자',
          })}: ${currentEdu?.eduCount}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`,
    [isAllEdu, eduSummary.data, eduSummary.data.length, eduTarget.data, currentEdu],
  );

  // 교육 삭제 버튼
  const deleteEduBtn = (
    <div
      className={`round-grey-button ${eduList.selectedList.length < 1 ? 'disabled' : ''}`}
      onClick={() => setModal('delete')}
      aria-hidden="true"
    >
      {formatMessage({ id: 'Edu_49', defaultMessage: '교육 삭제' })}
    </div>
  );

  const buttonComponent = (
    <div className="flex">
      {eduSummary?.selectedEduNo !== 'all' && (
        <button
          className={`round-grey-button ${eduTarget.selectedList.length < 1 ? 'disabled' : ''}`}
          type="button"
          onClick={() => setModal('resend')}
        >
          {formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
        </button>
      )}
      <button
        className={`round-grey-button ${
          eduSummary?.selectedEduNo === 'all' && eduList?.selectedList?.length < 1 ? 'disabled' : ''
        }`}
        type="button"
        onClick={() => onDownloadReport()}
      >
        {formatMessage({ id: 'Exam_48', defaultMessage: '보고서' })}
        <div className="download-img">
          <img src="/img/exam/menu_down.png" alt="down" />
        </div>
      </button>
    </div>
  );

  const eduSort = useMemo(() => {
    return {
      send: formatMessage({ id: 'Send_1', defaultMessage: '발송' }),
      read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
      beforeCount: formatMessage({ id: 'Status_15', defaultMessage: '진행전' }),
      playCount: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
      afterCount: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
    };
  }, []);

  const eduFilter = useMemo(() => {
    return {
      status: {
        name: formatMessage({ id: 'Status_1', defaultMessage: '상태' }),
        child: [
          { label: formatMessage({ id: 'Send_1', defaultMessage: '발송' }), value: 'send' },
          { label: formatMessage({ id: 'Read_1', defaultMessage: '열람' }), value: 'read' },
          {
            label: formatMessage({ id: 'Status_15', defaultMessage: '진행전' }),
            value: 'beforeCount',
          },
          {
            label: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
            value: 'playCount',
          },
          {
            label: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
            value: 'afterCount',
          },
        ],
      },
    };
  }, []);

  const eduTargetSort = useMemo(() => {
    return {
      grade: formatMessage({ id: 'Edu_104', defaultMessage: '점수' }),
      read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
      finish: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
    };
  }, []);

  const eduTargetFilter = useMemo(() => {
    return {
      status: {
        name: formatMessage({ id: 'Status_1', defaultMessage: '상태' }),
        child: [
          { label: formatMessage({ id: 'Edu_105', defaultMessage: '미열람' }), value: 'notRead' },
          { label: formatMessage({ id: 'Edu_106', defaultMessage: '미이수' }), value: 'notFinish' },
        ],
      },
    };
  }, []);

  return (
    <div className="common-content-layout">
      <div className="common-left-side">
        <EduSidebar />
      </div>
      <div className="common-content">
        <div className="common-inner">
          <ContentHeader
            title={headerTitle}
            subTitle={headerSubTitle}
            buttons={buttonComponent}
            onRefresh={onEduRefresh}
          />

          <div className="edu-content-wrap pi">
            {/* 검색 영역 */}
            <SearchFilter
              sortMenu={isAllEdu ? eduSort : eduTargetSort}
              filterMenu={isAllEdu ? eduFilter : eduTargetFilter}
              filterType="array"
              param={isAllEdu ? eduList.param : eduTarget.param}
              paramAction={({ key, value }: any) => {
                return changeState({
                  key: isAllEdu ? 'eduList' : 'eduTarget',
                  value: { [key]: value },
                });
              }}
              dataList={Object.keys(isAllEdu ? eduList.dataByEduNo : eduTarget.dataByEduNo).map(
                (key: any) => {
                  return parseInt(key, 10);
                },
              )}
              selectedList={isAllEdu ? eduList.selectedList : eduTarget.selectedList}
              selectedType={isAllEdu ? eduList.selectType : eduTarget.selectType}
              allCheckText={{
                allText: formatMessage({ id: 'Filter_14', defaultMessage: '전체선택' }),
                noneText: formatMessage({ id: 'Filter_15', defaultMessage: '전체해제' }),
              }}
              extraBtn={isAllEdu && deleteEduBtn}
            />

            {isAllEdu ? (
              <ContentList
                dataList={eduList.data}
                onLoadData={onGetEduList}
                loading={loading}
                page={eduList.page}
                totalPage={eduList.totalPage}
                noContent={{
                  title: formatMessage({ id: 'Edu_23', defaultMessage: '교육 대상자가 없습니다.' }),
                  subTitle: formatMessage({ id: 'Edu_24', defaultMessage: '교육을 실시하세요.' }),
                }}
              >
                {eduList.data.map((rowData: any) => {
                  return (
                    <div
                      className={`content-list-item ${
                        eduList.selectedList.includes(rowData.eduNo) &&
                        eduList.selectType === 'select'
                          ? 'selected'
                          : ''
                      }`}
                      key={rowData.eduNo}
                      onClick={() => onSelectItem('select', rowData.eduNo)}
                      aria-hidden="true"
                    >
                      <EduItem
                        data={rowData}
                        selectedEduNo={eduSummary.selectedEduNo}
                        // eduFileInfo={rowData.fileInfo}
                        quizInfo={rowData.quizData}
                        isChecked={eduList.selectedList.includes(rowData.eduNo)}
                        selectedType={eduList.selectType}
                        onSelectItem={(no: any) => onSelectItem('check', no)}
                      />
                    </div>
                  );
                })}
              </ContentList>
            ) : (
              <ContentList
                dataList={eduTarget.data}
                onLoadData={onGetEduTarget}
                loading={loading}
                page={eduTarget.page}
                totalPage={eduTarget.totalPage}
                noContent={{
                  title: formatMessage({ id: 'Edu_23', defaultMessage: '교육 대상자가 없습니다.' }),
                  subTitle: formatMessage({ id: 'Edu_24', defaultMessage: '교육을 실시하세요.' }),
                }}
              >
                {eduTarget.data.map((rowData: any) => {
                  return (
                    <div
                      className={`content-list-item ${
                        !isAllEdu &&
                        eduTarget.selectedList.includes(rowData.targetNo) &&
                        eduTarget.selectType === 'select'
                          ? 'selected'
                          : ''
                      }`}
                      key={rowData.targetNo}
                      onClick={() => onSelectItem('select', rowData.targetNo)}
                      aria-hidden="true"
                    >
                      <EduItem
                        data={rowData}
                        selectedEduNo={eduSummary.selectedEduNo}
                        // eduFileInfo={isAllEdu ? null : currentEdu?.fileInfo}
                        quizInfo={isAllEdu ? null : currentEdu?.quizData}
                        isChecked={eduTarget.selectedList.includes(rowData.targetNo)}
                        selectedType={eduTarget.selectType}
                        onSelectItem={(no: number) => onSelectItem('check', no)}
                      />
                    </div>
                  );
                })}
              </ContentList>
            )}
          </div>
        </div>

        {/* eduTarget */}
        <div className="common-inner-right">
          {!currentEdu ? (
            <Dimmer
              dimmerText={formatMessage({ id: 'Edu_18', defaultMessage: '교육을 선택하세요.' })}
            />
          ) : (
            <EduDetail
              detailType={
                eduSummary.selectedEduNo !== 'all' && eduTarget.selectedList?.length > 0
                  ? 'target'
                  : 'edu'
              }
              detailData={
                eduSummary.selectedEduNo !== 'all' && eduTarget.selectedList?.length > 0
                  ? eduTarget.dataByEduNo[eduTarget.selectedList[eduTarget.selectedList.length - 1]]
                  : currentEdu
              }
              eduSummaryData={currentEdu}
            />
          )}
        </div>

        {/* 교육 삭제 모달 */}
        {modal === 'delete' && (
          <ModalTemplate
            className="modal-464"
            visible={modal === 'delete'}
            title={formatMessage({ id: 'Edu_49', defaultMessage: '교육 삭제' })}
            onOk={onDeleteEdu}
            onCancel={() => setModal('')}
            okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
            greyButton
          >
            <div className="modal-explain-text">
              <div>
                {formatMessage({
                  id: 'Edu_50',
                  defaultMessage: '삭제한 교육 정보는 복구할 수 없습니다.',
                })}
              </div>
              <div>
                {formatMessage({
                  id: 'Edu_51',
                  defaultMessage: '다음 정보를 삭제합니까?',
                })}
              </div>
            </div>
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Edu_81', defaultMessage: '교육 번호' })}
                  </div>
                </li>
                <div>{eduList.selectedList.join(', ')}</div>
              </ul>
            </div>
          </ModalTemplate>
        )}

        {/* 교육 메일(촉구메일) 재전송 모달 */}
        {modal === 'resend' && (
          <ModalTemplate
            className="modal-464"
            visible={modal === 'resend'}
            title={formatMessage({ id: 'Resend_12', defaultMessage: '교육 메일 재전송' })}
            onOk={onResend}
            onCancel={() => setModal('')}
            okText={formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
            greyButton
          >
            <div className="modal-explain-text">
              <div>
                {formatMessage({
                  id: 'Resend_13',
                  defaultMessage: '교육 메일을 다시 보내시겠습니까?',
                })}
              </div>
            </div>
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}
                  </div>
                </li>
                <div className="item-title">{currentEdu.eduName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
                  </div>
                </li>
                <div className="item-title">{`${timeFormatFromUTCEpoch(
                  currentEdu?.startEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(currentEdu?.endEpoch, 3)}`}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                  </div>
                </li>
                <div>{eduTarget.selectedList.join(', ')}</div>
              </ul>
            </div>
          </ModalTemplate>
        )}
      </div>
    </div>
  );
}

export default Eduresult;
