import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Menu, Progress, Tooltip } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';

// import settingImage from 'img/exam/setting.png';
// import eduTitleImage from 'img/edu/icon_edu_title.png';

interface eduSidebarProps {
  summaryInfo: any;
  selectedItem: string | number;
  onClickItem: any;
  onClickDropdownMenu: any;
}

function EduSidebarItem({
  summaryInfo,
  selectedItem,
  onClickItem,
  onClickDropdownMenu,
}: eduSidebarProps) {
  const { formatMessage } = useIntl();

  // 드롭다운 설정 메뉴
  const settingMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          onClickDropdownMenu(summaryInfo.eduNo, 'eduEdit');
        }}
      >
        <EditFilled />
        {formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          onClickDropdownMenu(summaryInfo.eduNo, 'eduDelete');
        }}
      >
        <DeleteFilled />
        {formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
      </Menu.Item>
    </Menu>
  );

  // 교육 진행상태
  let progress = 1;
  // 예약 교육 여부
  let isReserveEdu = false;

  if (summaryInfo.startEpoch > nowEpoch()) {
    progress = 0;
    isReserveEdu = true;
  } else {
    progress = Math.round(
      ((nowEpoch() - summaryInfo.startEpoch) / (summaryInfo.endEpoch - summaryInfo.startEpoch)) *
      100,
    );

    if (progress < 1) {
      progress = 1;
    } else if (progress >= 100) {
      progress = 100;
    }
  }

  return (
    <div
      className={`summary-item-wrap summary-item edu ${selectedItem === summaryInfo.eduNo ? 'selected' : ''
        }`}
      onClick={() => onClickItem(summaryInfo.eduNo)}
      aria-hidden="true"
    >
      <div className="summary-item-inner">
        <div className="inner-top">
          {/* 예약 훈련 아이콘 */}
          {isReserveEdu && (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="top"
              title={formatMessage({ id: 'Edu_20', defaultMessage: '예약 교육' })}
            >
              <div className="send-icon">
                <img src="/img/exam/send_reserve.png" alt="reserve" />
              </div>
            </Tooltip>
          )}
          <div className="exam-text ellipsis">{summaryInfo.eduName}</div>
          <div
            className="exam-buttons"
            onClick={(e: any) => {
              // 훈련이 선택되지 않도록 하기 위함
              e.stopPropagation();
            }}
            aria-hidden="true"
          >
            {/* 설정 */}
            <Dropdown
              getPopupContainer={(triggerNode: any) => triggerNode.parentElement} // 부모 노드와 함께 움직이도록 설정
              overlayClassName="summary-dropdown"
              overlay={settingMenu}
              trigger={['click']}
              placement="bottomRight"
            >
              {/* <img className="icon setting" src={settingImage} alt="setting" /> */}
              <img className="icon setting" src="/img/exam/setting.png" alt="setting" />
            </Dropdown>
          </div>
        </div>
        <div className="inner-bottom">
          <div className="summary-detail">
            {/* <div className="edu-row">
              <img src="/img/edu/icon_edu_title.png" alt="icon" />
              {summaryInfo.attachName}
            </div> */}
            <div className="edu-row">
              {`${timeFormatFromUTCEpoch(summaryInfo.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                summaryInfo.endEpoch,
                3,
              )}`}
            </div>

            <div className="bg-box">
              <div className="bg-inner top">
                <div className="inner-row">
                  <div className="text">
                    {formatMessage({ id: 'Edu_112', defaultMessage: '교 육' })}
                  </div>
                  <div className="value">{`${summaryInfo.examCount}${formatMessage({
                    id: 'Exam_26',
                    defaultMessage: '개',
                  })}`}</div>
                </div>
              </div>
              <div className="bg-inner bottom">
                <div className="inner-row">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Edu_110', defaultMessage: '교육 대상' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.eduCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                <div className="inner-row li">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Status_15', defaultMessage: '진행전' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.beforeCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                <div className="inner-row li">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.playCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                <div className="inner-row li">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.afterCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
              </div>
            </div>

            <Tooltip
              className="flex"
              overlayClassName="black-tooltip"
              placement="bottom"
              title={
                isReserveEdu
                  ? formatMessage({ id: 'Edu_20', defaultMessage: '예약 교육' })
                  : `${formatMessage({
                    id: 'Template_28',
                    defaultMessage: '진행률',
                  })}: ${progress}%`
              }
            >
              <Progress
                className={`${isReserveEdu ? 'disabled' : ''}`}
                percent={progress}
                status="active"
                showInfo={false}
                strokeColor={progress === 100 ? '#14ae96' : '#ee4f4f'}
                strokeWidth={6}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EduSidebarItem;
