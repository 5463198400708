import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useRouteMatch, Switch, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  getEduTitle,
  changeState,
  eduLogin,
  findPassword,
  eduCheckLogin,
  getEdu,
} from 'store/edumain';
import AutoRoute from 'components/common/AutoRoute';
import './Edumain.scss';
import Loading from 'components/common/Loading';
import { message } from 'antd';
import { Option, changeOptions } from 'utils/commonValues';

export const authLevel = 0;
function Edumain() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const edumainStore = useSelector((state: any) => {
    return {
      serviceNo: state.edumain.serviceNo,
      userData: state.edumain.userData,
      japanMode: state.edumain.japanMode,
    };
  }, shallowEqual);

  useEffect(() => {
    const params = window.location.search;
    const serviceNo = params?.split('serviceNo=')[1];

    // 서비스 번호 스토어에 저장
    if (serviceNo) {
      dispatch(changeState({ key: 'serviceNo', value: serviceNo }));
    }

    // 로그인 여부 확인
    onCheckEduLogin(serviceNo);

    // 제목 조회
    onGetTitle(serviceNo);
  }, [dispatch]);

  // 로그인 여부 확인
  const onCheckEduLogin = async (serviceNo: any) => {
    try {
      const response: any = await dispatch(eduCheckLogin());
      const { loginResult, userData, isJapan, serviceName } = response?.data?.list;
      // 환경변수에 따라 브라우저탭 타이틀 적용
      document.title = serviceName || 'MudFix';
      // 일본용인지 확인
      await dispatch(changeState({ key: 'japanMode', value: isJapan }));

      if (loginResult === 1 && userData?.isActive === 1) {
        history.push(`/edumain/eduplay?serviceNo=${serviceNo}`);
      } else if (
        loginResult === 2 ||
        userData?.isActive === 0 ||
        window.location.pathname?.includes('findpassword')
      ) {
        // 최초 로그인 or 비밀번호 찾기 시 해당 페이지로 이동
      } else {
        // 로그인이 되어있지 않으면 로그인 페이지로 이동
        history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
      }
    } catch (error) {
      console.log('Edumain onCheckEduLogin', error);
    }
  };

  // 교육 페이지 타이틀 조회
  const onGetTitle = async (serviceNo: any) => {
    try {
      const params = {
        serviceNo: serviceNo,
      };
      const response: any = await dispatch(getEduTitle(params));
      if (response?.data?.error && response.data.error[0]?.errInfo) {
        message.warning(response.data.error[0].errInfo, 2);
      }
    } catch (error) {
      console.log('Edulogin onGetTitle', error);
    }
  };

  return (
    <div className={`edu-main ${edumainStore.japanMode ? 'green-theme' : ''}`}>
      <Switch>
        <Route path={`${url}/:name`} component={AutoRoute} />
        <Redirect path="*" to={`/edumain/edulogin?serviceNo=${edumainStore.serviceNo}`} />
      </Switch>
    </div>
  );
}

export default Edumain;
