import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  Sector,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { fileSizeTransform } from 'utils/commonFunctions';

// import targetImage from 'img/exam/chart/each_target.png';
// import sendImage from 'img/exam/chart/each_send.png';
// import readImage from 'img/exam/chart/each_read.png';
// import downImage from 'img/exam/chart/each_down.png';
// import infectionImage from 'img/exam/chart/each_infection.png';
// import accessImage from 'img/exam/chart/each_access.png';
// import phishingImage from 'img/exam/chart/each_phishing.png';
// import cureImage from 'img/exam/chart/each_cure.png';
// import noDataImage from 'img/exam/chart/chart_no_data.png';

function ExamChart({ chartType }: any) {
  const { formatMessage } = useIntl();
  const summaryData = useSelector((state: any) => state.exam.examSummary);
  const chartData = useSelector((state: any) => state.exam.examChart.data);
  const examData = summaryData?.dataByExamNo[summaryData?.selectedExamNo] || {};
  const [chartTab, setChartTab] = useState('division');
  const { url } = useRouteMatch();
  // const infectionPercent = (examData.infection / examData.target) * 100;
  const fileSize = fileSizeTransform(examData.leakFileSize, null);
  const certSize = fileSizeTransform(examData.leakCertSize, null);
  const emailSize = fileSizeTransform(examData.leakEmailSize, null);

  // 감염 파일 차트 데이터(PC파일, 인증서, 이메일)
  const circleChartArray = [
    {
      name: '',
      color: '',
      value: examData.leakAllcount > 0 ? 0 : 100,
      file: '',
      label: '',
    },
    {
      name: 'pc',
      color: '#43cbaa',
      value: examData.leakFileCount,
      file: `${fileSize.common}`,
      label: formatMessage({ id: 'Infection_30', defaultMessage: 'pc 파일' }),
    },
    {
      name: 'cert',
      color: '#7b3faf',
      value: examData.leakCertCount,
      file: `${certSize.common}`,
      label: formatMessage({ id: 'Infection_31', defaultMessage: '인증서' }),
    },
    {
      name: 'email',
      color: '#6697da',
      value: examData.leakEmailCount,
      file: `${emailSize.common}`,
      label: formatMessage({ id: 'Exam_80', defaultMessage: '이메일' }),
    },
  ];

  // 감염 대상 차트 데이터(소속별, 직급별, 태그별)
  let barChartKeys: any = {};
  let barChartArray: any = [];
  if (url === '/exam/pi') {
    if (chartTab === 'division') {
      // 소속별
      barChartKeys = {
        emptyName: formatMessage({ id: 'Division_2', defaultMessage: '소속 없음' }),
        name: 'targetDivision',
        chartType: 'pi',
      };
      barChartArray = chartData[0]?.division;
    } else {
      // 직급별
      barChartKeys = {
        emptyName: formatMessage({ id: 'Position_2', defaultMessage: '직급 없음' }),
        name: 'targetPosition',
        chartType: 'pi',
      };
      barChartArray = chartData[0]?.position;
    }
  } else {
    // 태그별
    barChartKeys = {
      emptyName: formatMessage({ id: 'Tag_11', defaultMessage: '태그가 없는 대상자' }),
      name: 'tagName',
      chartType: 'tag',
    };
    barChartArray = chartData.tag;
  }

  const chartDataArray: any = [];
  if (chartData && (chartData.length > 0 || chartData.tag?.length > 0) && barChartArray) {
    barChartArray.forEach((data: any) => {
      if (data.infection > 0) {
        chartDataArray.push({
          name: data[barChartKeys.name] || `(${barChartKeys.emptyName})`,
          infectionCnt: data.infection,
          infectionText: `${data.infection}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`,
          percent:
            barChartKeys.name === 'tagName'
              ? ((data.infection / data.targetCount) * 100).toFixed(0)
              : `${((data.infection / examData.infection) * 100).toFixed(0)}%`,
          total: barChartKeys.name === 'tagName' ? data.targetCount : examData.infection,
        });
      }
    });
  }

  return (
    <div className="exam-result-chart">
      {/* 훈련 상태 정보 */}
      <div className="chart-item left">
        <div className="chart-info">
          <div className="chart-row">
            <div className="row-text">
              {/* <img src={targetImage} alt="target" /> */}
              <img src="/img/exam/chart/each_target.png" alt="target" />
              {formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' })}
            </div>
            <div className="row-value">
              <span className="value-count color-brand-green">{examData.target}</span>
              <span className="value-unit color-brand-green">
                {formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
              </span>
            </div>
          </div>

          {/* 메일 발송 */}
          <div className="chart-row">
            <div className="row-text">
              {/* <img src={sendImage} alt="send" /> */}
              <img src="/img/exam/chart/each_send.png" alt="send" />
              {formatMessage({ id: 'Send_2', defaultMessage: '메일 발송' })}
            </div>
            <div className="row-value">
              <span className="value-count">{examData.send}</span>
              <span className="value-unit">
                {`/${examData.target}${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}
              </span>
            </div>
          </div>

          {/* 메일 열람 */}
          <div className="chart-row">
            <div className="row-text">
              {/* <img src={readImage} alt="read" /> */}
              <img src="/img/exam/chart/each_read.png" alt="read" />
              {formatMessage({ id: 'Read_2', defaultMessage: '메일 열람' })}
            </div>
            <div className="row-value">
              <span className="value-count">{examData.read}</span>
              <span className="value-unit">
                {`/${examData.target}${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}
              </span>
            </div>
          </div>

          {/* 파일 다운 / 피싱 접속 */}
          <div className="chart-row">
            <div className="row-text">
              {examData.examType === 4 ? (
                <>
                  {/* <img src={accessImage} alt="access" /> */}
                  <img src="/img/exam/chart/each_access.png" alt="access" />
                  {formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })}
                </>
              ) : (
                <>
                  {/* <img src={downImage} alt="down" /> */}
                  <img src="/img/exam/chart/each_down.png" alt="down" />
                  {formatMessage({ id: 'Exam_81', defaultMessage: '파일 다운' })}
                </>
              )}
            </div>
            <div className="row-value">
              <span className="value-count">{examData.download}</span>
              <span className="value-unit">
                {`/${examData.target}${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}
              </span>
            </div>
          </div>

          {/* 감염 / 정보 유출 */}
          <div className="chart-row">
            <div className="row-text">
              {examData.examType === 4 ? (
                <>
                  {/* <img src={phishingImage} alt="phishing" /> */}
                  <img src="/img/exam/chart/each_phishing.png" alt="phishing" />
                  {formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })}
                </>
              ) : (
                <>
                  {/* <img src={infectionImage} alt="infection" /> */}
                  <img src="/img/exam/chart/each_infection.png" alt="infection" />
                  {formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}
                </>
              )}
            </div>
            <div className="row-value">
              <span className="value-count">{examData.infection}</span>
              <span className="value-unit">
                {`/${examData.target}${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}
              </span>
            </div>
          </div>

          {/* 치료 */}
          {examData.examType === 1 && examData.attachData.attachExamType === 2 && (
            <div className="chart-row">
              <div className="row-text">
                {/* <img src={cureImage} alt="cure" /> */}
                <img src="/img/exam/chart/each_cure.png" alt="cure" />
                {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
              </div>
              <div className="row-value">
                <span className="value-count">{examData.cure}</span>
                <span className="value-unit">
                  {`/${examData.target}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* 유출률/감염률 */}
        <div className="percent">
          {examData.examType === 4
            ? formatMessage({ id: 'Template_20', defaultMessage: '유출률' })
            : formatMessage({ id: 'Template_19', defaultMessage: '감염률' })}
          <div className="percent-number">
            {((examData.infection / examData.target) * 100).toFixed(1)}
          </div>
          %
        </div>
      </div>

      {/* 원 차트 */}
      {examData.examType !== 4 && (
        <div className="chart-item center">
          <PieChart width={210} height={230}>
            <Pie
              dataKey="value"
              data={[
                {
                  name: '',
                  value: 100,
                },
              ]}
              cx={100}
              cy={98}
              paddingAngle={0}
              innerRadius={31}
              outerRadius={80}
              fill="#f7f7f7"
            />
            <Pie
              dataKey="value"
              data={circleChartArray}
              cx={100}
              cy={98}
              innerRadius={39}
              outerRadius={72}
              startAngle={450}
              endAngle={90}
              paddingAngle={-1}
              activeIndex={0}
              activeShape={(e: any) => {
                const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
                return (
                  <g>
                    <text
                      x={cx}
                      y={cy - 12}
                      dy={18}
                      textAnchor="middle"
                      fill="#757575"
                      fontSize={12}
                      fontWeight="bold"
                    >
                      {formatMessage({ id: 'Infection_16', defaultMessage: '감염 파일' })}
                    </text>
                    <defs>
                      {/* 파일 */}
                      <defs>
                        <linearGradient id="43cbaa" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#39cbd2" />
                          <stop offset="50%" stopColor="#77eadf" />
                          <stop offset="100%" stopColor="#28c3a4" />
                        </linearGradient>
                      </defs>
                      {/* 인증서 */}
                      <defs>
                        <linearGradient id="7b3faf" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#753eaa" />
                          <stop offset="50%" stopColor="#a33ede" />
                          <stop offset="100%" stopColor="#6e19cc" />
                        </linearGradient>
                      </defs>
                      {/* 이메일 */}
                      <defs>
                        <linearGradient id="6697da" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#619dd8" />
                          <stop offset="100%" stopColor="#6649d2" />
                        </linearGradient>
                      </defs>
                    </defs>
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill="#f7f7f7"
                    />
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={outerRadius - 1}
                      outerRadius={outerRadius + 8}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill="#f7f7f7"
                    />
                  </g>
                );
              }}
            >
              {/* 퍼센트 표시 */}
              {circleChartArray.map((entry: any) => (
                <Cell key={entry.name} fill={`url(${entry.color})`} />
              ))}
            </Pie>
            <Legend
              iconSize={10}
              width={190}
              height={30}
              align="center"
              wrapperStyle={{
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '20px',
                lineHeight: '20px',
              }}
              content={({ payload }: any) => {
                const legendItem = payload.filter(
                  (item: any) =>
                    item.value === 'pc' || item.value === 'cert' || item.value === 'email',
                );
                return (
                  <div className="chart-legend flex-between">
                    {legendItem.length > 0 &&
                      legendItem.map((data: any) => {
                        const { color, label } = data.payload;
                        return (
                          <div className="flex" key={data.name}>
                            <div className="color" style={{ backgroundColor: color }} />
                            <div className="message">{label}</div>
                          </div>
                        );
                      })}
                  </div>
                );
              }}
            />
            <ChartTooltip
              content={({ payload }: any) => {
                return (
                  <div>
                    {payload.length > 0 &&
                      payload[0]?.name &&
                      payload.map((data: any) => (
                        <div className="rechart-tooltip" key={data.name}>
                          <div className="title">{data.payload.label}</div>
                          <div className="value">
                            {`${data.payload.value}${formatMessage({
                              id: 'StartExam_21',
                              defaultMessage: '개',
                            })} (${data.payload.file})`}
                          </div>
                        </div>
                      ))}
                  </div>
                );
              }}
            />
          </PieChart>
        </div>
      )}

      {/* 막대 차트 */}
      <div className="chart-item right">
        {chartType === 'pi' && (
          <div className="chart-tab-header">
            <div
              className={`tab-name ${chartTab === 'division' ? 'active' : ''}`}
              onClick={() => setChartTab('division')}
              aria-hidden="true"
            >
              {examData.examType === 4
                ? formatMessage({ id: 'Infection_32', defaultMessage: '소속별 정보 유출 대상' })
                : formatMessage({ id: 'Infection_5', defaultMessage: '소속별 감염 대상' })}
            </div>
            <div
              className={`tab-name ${chartTab === 'position' ? 'active' : ''}`}
              onClick={() => setChartTab('position')}
              aria-hidden="true"
            >
              {examData.examType === 4
                ? formatMessage({ id: 'Infection_34', defaultMessage: '직급별 정보 유출 대상' })
                : formatMessage({ id: 'Infection_7', defaultMessage: '직급별 감염 대상' })}
            </div>
          </div>
        )}
        {chartType === 'tag' && (
          <div className="chart-tab-header">
            <div className="tab-name active" aria-hidden="true">
              {examData.examType === 4
                ? formatMessage({ id: 'Infection_33', defaultMessage: '태그별 정보 유출 대상' })
                : formatMessage({ id: 'Infection_6', defaultMessage: '태그별 감염 대상' })}
            </div>
          </div>
        )}

        <div className="chart-tab-content">
          {chartDataArray.length > 0 ? (
            <BarChart
              layout="vertical"
              width={350}
              height={170}
              data={chartDataArray}
              margin={{ top: 10, right: 60, bottom: 10, left: 10 }}
            >
              <XAxis hide type="number" />
              <YAxis dataKey="name" width={90} tickLine={false} tickSize={10} type="category" />
              <ChartTooltip
                cursor={false}
                content={({ payload }: any) => {
                  return (
                    <div>
                      {payload &&
                        payload.map((data: any) => (
                          <div className="rechart-tooltip" key={data.name}>
                            <div className="title">{data.payload.name}</div>
                            <div className="value">
                              {`${data.payload.infectionCnt}${formatMessage({
                                id: 'StartExam_35',
                                defaultMessage: '명',
                              })}`}
                            </div>
                          </div>
                        ))}
                    </div>
                  );
                }}
              />
              <Bar dataKey="infectionCnt" barSize={7} fill="#14ae96" isAnimationActive={false}>
                <LabelList
                  fill="#777"
                  position="right"
                  content={({ x, y, width, value }: any) => {
                    return (
                      <g>
                        <text
                          className="bar-text"
                          x={x + width + 20}
                          y={y + 7}
                          fill="#333"
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan className="bar-title">{value}</tspan>
                          <tspan className="bar-content">
                            {formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
                          </tspan>
                        </text>
                      </g>
                    );
                  }}
                />
              </Bar>
            </BarChart>
          ) : (
            <div className="no-data">
              {/* <img src={noDataImage} alt="noData" /> */}
              <img src="/img/exam/chart/chart_no_data.png" alt="noData" />
              <div>
                {examData.examType === 4
                  ? formatMessage({ id: 'Infection_35', defaultMessage: '정보 유출 대상자 없음' })
                  : formatMessage({ id: 'Infection_10', defaultMessage: '감염 대상자 없음' })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExamChart;
