import { useEffect, useMemo, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { changeParam, getLicense } from 'store/license';
import { Option } from 'utils/commonValues';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import LicenseSettings from 'components/branch/license/LicenseSettings';

export default function LicenseList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.license.param.sort,
      filter: state.license.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.license.needReload);
  const respData = useSelector((state: any) => state.license.data);
  const totalCount = useSelector((state: any) => state.license.totalCount);
  const totalPages = useSelector((state: any) => state.license.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    getLicensehData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getLicensehData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        await dispatch(getLicense(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  const LicenseString: any = { 
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration: formatMessage({ id: 'License_60', defaultMessage: '[교육]연간 라이선스' }),
    EduDurationTimes: formatMessage({ id: 'License_61', defaultMessage: '[교육]횟수 교육 라이선스' })
  };

  if(Option.isJapan === 1){
    LicenseString.Duration = formatMessage({ id: 'License_63', defaultMessage: '[훈련]기간 라이선스' });
    LicenseString.Times = formatMessage({ id: 'License_64', defaultMessage: '[훈련]횟수 라이선스(무제한)' });
    LicenseString.DurationTimes = formatMessage({ id: 'License_65', defaultMessage: '[훈련]횟수 라이선스' });
    LicenseString.EduDuration = formatMessage({ id: 'License_60', defaultMessage: '[교육]연간 라이선스' });
    LicenseString.EduDurationTimes = formatMessage({ id: 'License_61', defaultMessage: '[교육]횟수 라이선스' });
  }
  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    serviceName: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
    licenseNo: 'No',
    orderUserName: formatMessage({ id: 'License_14', defaultMessage: '신청인' }),
    orderUserEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {
    licenseTypeArray: {
      name: formatMessage({ id: 'License_18', defaultMessage: '라이선스 타입' }),
      child: [
        {
          label: formatMessage({ id: 'License_15', defaultMessage: '영구 라이선스' }),
          value: '1',
        },
        {
          label: LicenseString.Duration,
          value: '2',
        },
        {
          label: LicenseString.Times,
          value: '4',
        },
        {
          label: LicenseString.DurationTimes,
          value: '8',
        },
        {
          label: LicenseString.EduDuration,
          value: '16',
        },
        {
          label: LicenseString.EduDurationTimes,
          value: '32',
        },
      ],
    },
    statusArray: {
      name: formatMessage({ id: 'Status_1', defaultMessage: '상태' }),
      child: [
        {
          label: formatMessage({ id: 'Status_9', defaultMessage: '미존재' }),
          value: '-1',
        },
        {
          label: formatMessage({ id: 'Status_2', defaultMessage: '신청' }),
          value: '0',
        },
        {
          label: formatMessage({ id: 'Status_4', defaultMessage: '사용중' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'Status_5', defaultMessage: '사용대기' }),
          value: '2',
        },
        {
          label: formatMessage({ id: 'Status_7', defaultMessage: '만료' }),
          value: '3',
        },
        {
          label: formatMessage({ id: 'Status_6', defaultMessage: '신청취소' }),
          value: '10',
        },
      ],
    },
  };
  // 테이블
  const baseColumns: Array<{ [key: string]: any }> = [
    {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
      accessor: 'serviceName',
      resizable: false,
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center sub-text',
    },
    {
      Header: 'No',
      accessor: 'licenseNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'License_18', defaultMessage: '라이선스 타입' }),
      accessor: 'licenseType',
      resizable: false,
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = formatMessage({ id: 'License_15', defaultMessage: '영구 라이선스' });
        } else if (props.value === 2) {
          printValue = LicenseString.Duration;
        } else if (props.value === 4) {
          printValue = LicenseString.Times;
        } else if (props.value === 8) {
          printValue = LicenseString.DurationTimes;
        } else if (props.value === 16) {
          printValue = LicenseString.EduDuration;
        } else if (props.value === 32) {
          printValue = LicenseString.EduDurationTimes;
        }else {
          //
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' }),
      accessor: 'examLimitCount',
      resizable: false,
      sortable: false,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        if (props.row.original.licenseType === 4 || 
          props.row.original.licenseType === 8 ||
          props.row.original.licenseType === 32 ) {
          return `${props.row.original.examUsedCount} / ${
            props.row.original.examLimitCount
          } ${formatMessage({ id: 'User_8', defaultMessage: '회' })}`;
        }
        return '-';
      },
    },
    {
      Header: formatMessage({ id: 'Date_11', defaultMessage: '시작일자' }),
      accessor: 'startEpoch',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{value ? moment.unix(value).format(`${dateFormat}`) : '-'}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Date_12', defaultMessage: '종료일자' }),
      accessor: 'endEpoch',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{value ? moment.unix(value).format(`${dateFormat}`) : '-'}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' }),
      accessor: 'targetCount',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        if (props.value) {
          return `${props.value}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`;
        }
        return '-';
      },
    },
    {
      Header: formatMessage({ id: 'License_16', defaultMessage: '수량' }),
      accessor: 'targetUsedCount',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        if (props.value) {
          return `${props.value}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`;
        }
        return '-';
      },
    },
    {
      Header: formatMessage({ id: 'License_17', defaultMessage: '구매 수량' }),
      accessor: 'licenseCount',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        if (props.value) {
          return `${props.value}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`;
        }
        return '-';
      },
    },
    {
      Header: formatMessage({ id: 'Status_1', defaultMessage: '상태' }),
      accessor: 'status',
      resizable: false,
      sortable: false,
      width: 83,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        if (props.value) {
          let printValue = formatMessage({ id: 'Status_9', defaultMessage: '미존재' });
          if (props.value === 0) {
            printValue = formatMessage({ id: 'Status_2', defaultMessage: '신청' });
          } else if (props.value === 1) {
            printValue = formatMessage({ id: 'Status_4', defaultMessage: '사용중' });
          } else if (props.value === 2) {
            printValue = formatMessage({ id: 'Status_5', defaultMessage: '사용대기' });
          } else if (props.value === 3) {
            printValue = formatMessage({ id: 'Status_7', defaultMessage: '만료' });
          } else if (props.value === 10) {
            printValue = formatMessage({ id: 'Status_6', defaultMessage: '신청취소' });
          }
          return printValue;
        }
        return '-';
      },
    },
    {
      Header: formatMessage({ id: 'License_14', defaultMessage: '신청인' }),
      accessor: 'orderUserName',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center sub-text',
    },
    {
      Header: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      accessor: 'orderUserEmail',
      resizable: false,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: formatMessage({ id: 'Date_5', defaultMessage: '발급일시' }),
      accessor: 'issueEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return value ? <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div> : '-';
      },
    },
    {
      Header: formatMessage({ id: 'Date_6', defaultMessage: '신청일시' }),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      accessor: 'licenseEnabled',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 0) {
          printValue = 'O';
        }
        return printValue;
      },
    },
  ];

  if (Option.isSaas === 1) {
    baseColumns.unshift({
      Header: <SettingOutlined />,
      accessor: 'setting',
      sortable: false,
      resizable: false,
      width: 50,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <LicenseSettings isAdmin={isAdmin} dataInfo={props.row.original} />;
      },
    });
  }

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
        disableKey="licenseEnabled"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
