import ModalTemplate from "components/common/ModalTemplate";
import FormSwitchField from "components/common/FormSwitchField";
import FormTextField from "components/common/FormTextField"
import React, { useState } from "react";
import { useIntl } from 'react-intl';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as valid from 'utils/validation';
import { postReceiveTest } from 'store/template';

import './ExamTemplateReceiveTest.scss'


export default function ExamTemplateMenu(props: any) {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const { isModal, setIsModal, examTemplateArray, serviceTemplateArray } = props
  const { errors, register, getValues, setValue, clearErrors, handleSubmit, control } = useForm({
    mode: "onChange"
  })
  const { senderEmail, forceTest } = getValues();

  const receiveTest = async (formData: any) => {
    setLoading(true)
    const params = {
      examTemplateNoArray: examTemplateArray,
      serviceTemplateNoArray: serviceTemplateArray,
      forceTest: formData.forceTest ? 1 : 0,
      senderEmail: formData.senderEmail
    }
    await dispatch(postReceiveTest(params))
    setLoading(false)
    setIsModal(false)
    setValue('forceTest', false)
    setValue('senderEmail', "")
  }


  return (
    <div className="exam-template-receive-test">
      <ModalTemplate
        visible={isModal}
        className="modal-464"
        onOk={handleSubmit(receiveTest)}
        onCancel={() => {
          setIsModal(false)
          setValue('forceTest', false)
          setValue('senderEmail', "")
          clearErrors()
        }}
        loading={loading}
        title={formatMessage({ id: 'Template_29', defaultMessage: '훈련 양식(템플릿) 수신 테스트' })}
        okText={formatMessage({ id: 'Button_9', defaultMessage: '실 행' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      >
        <form autoComplete="off">
          <div className="modal-text">
            <div className="modal-text-title">
              {formatMessage({ id: 'Template_30', defaultMessage: '훈련 템플릿을 본인 환경의 이메일로 발송하여' })}
              <br />
              {formatMessage({ id: 'Template_31', defaultMessage: '정상 수신 여부를 확인합니다.' })}
              <br />
              {formatMessage({ id: 'Template_32', defaultMessage: '(피싱 템플릿은 단순 메일만 확인 가능)' })}
            </div>
            <div className="template-detail-box">
              <div className="template-detail">
                <div className="template-modal-content">
                  <FormSwitchField
                    className="input-switch"
                    control={control}
                    name="forceTest"
                    value={forceTest}
                    setValue={setValue}
                    // handleOnChange={onForce}
                  />
                  <div className="modal-swich-label">
                    {formatMessage({ id: 'Template_33', defaultMessage: '기존에 수신 성공한 템플릿도 다시 발송' })}
                  </div>
                </div>
                <div className="config-row-option-text-test">
                  {formatMessage({ id: 'Email_26', defaultMessage: '수신자 이메일' })}
                </div>
                <FormTextField
                  name="senderEmail"
                  error={errors.senderEmail}
                  register={register}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                      email: (value: any) => valid.email(value)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </ModalTemplate>
    </div>
  );
}