import { Button, Dropdown, Menu, Tooltip } from 'antd';
import TableFilter from 'components/common/TableFilter';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import './TableSearch.scss';

// import filterImage from 'img/table/btn_filter.png';
// import filterImageActive from 'img/table/btn_filter_active.png';
// import searchImage from 'img/table/btn_search.png';
// import arrowImage from 'img/table/selectbox_number_arrow.png';

export default function TableSearch(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    changeParam,
    setPageSize,
    pageSize,
    totalCount,
    currentPage,
    setCurrentPage,
    searchOption,
    filter,
    filterOption,
  } = props;
  const [searchType, setSearchType] = useState(
    formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
  );
  const [searchTypeKey, setSearchTypeKey] = useState('searchAll');
  const [searchText, setSearchText] = useState('');
  const [filterList, setFilterList] = useState<{ [key: string]: any }>(filter);

  const startCount = pageSize * currentPage + 1;
  let endCount = pageSize * (currentPage + 1);
  if (endCount > totalCount) {
    endCount = totalCount;
  }

  useEffect(() => {
    return () => {
      setSearchText('');
      dispatch(changeParam({ name: 'filter', value: {} }));
    };
  }, []);

  // 페이지 개수 변경
  const changePageSize = (props: any) => {
    setPageSize(props.key);
    setCurrentPage(0);
  };

  // 검색 타입 변경
  const changeSearchType = (props: any) => {
    setSearchType(searchOption[props.key]);
    setSearchTypeKey(props.key);
  };

  // 검색 타입 리스트 설정
  const search: Array<any> = [];
  Object.keys(searchOption).forEach((key: any) => {
    search.push(<Menu.Item key={key}>{searchOption[key]}</Menu.Item>);
  });
  const searchList = <Menu onClick={changeSearchType}>{search}</Menu>;

  // 검색 텍스트 변경
  const changeSearchText = (e: ChangeEvent<any>) => {
    setSearchText(e.target.value);
  };

  // 검색 실행
  const excuteSearchText = () => {
    // 검색 실행 시 첫 offset부터 검색하도록 수정
    setCurrentPage(0);
    Object.keys(searchOption).forEach((key: any) => delete filter[key]);

    let searchTextResult: string | Array<string> = searchText;

    if (searchTypeKey.includes('Array')) {
      searchTextResult = [searchTextResult];
    }

    dispatch(
      changeParam({
        name: 'filter',
        value: searchTextResult ? { ...filter, [searchTypeKey]: searchTextResult } : { ...filter },
      }),
    );
  };

  // 필터 초기화
  const initFilter = () => {
    // if (this.myTextInput && this.myTextInput.inputRef.value !== '') {
    //   this.myTextInput.inputRef.value = '';
    // }
    setSearchType(formatMessage({ id: 'Faq_1', defaultMessage: '전체' }));
    setSearchTypeKey('searchAll');
    setSearchText('');
    setFilterList({});

    dispatch(
      changeParam({
        name: 'filter',
        value: {},
      }),
    );

    // this.setState({ searchType: 'searchAll', searchText: '', tempFilter: {} });
  };

  // 페이지 사이즈 목록
  const pageSizeList = (
    <Menu onClick={changePageSize}>
      <Menu.Item style={{ textAlign: 'center' }} key="5">
        5
      </Menu.Item>
      <Menu.Item style={{ textAlign: 'center' }} key="10">
        10
      </Menu.Item>
      <Menu.Item style={{ textAlign: 'center' }} key="20">
        20
      </Menu.Item>
      <Menu.Item style={{ textAlign: 'center' }} key="25">
        25
      </Menu.Item>
      <Menu.Item style={{ textAlign: 'center' }} key="50">
        50
      </Menu.Item>
      <Menu.Item style={{ textAlign: 'center' }} key="100">
        100
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="react-search">
      {/* 검색 영역 */}
      <div className="seacrch-input-area">
        <div className="search-dropdown-area">
          <div className="dropdown-arrow-area">
            <img src="/img/table/selectbox_number_arrow.png" alt="" />
          </div>
          <Dropdown className="search-dropdown" overlay={searchList} trigger={['click']}>
            <div className="text">{searchType}</div>
          </Dropdown>
        </div>
        <div className="react-search-input">
          <input
            placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
            value={searchText}
            onChange={changeSearchText}
            onKeyDown={(e) => (e.key === 'Enter' ? excuteSearchText() : null)}
          />
          <Button id="bt-search-input" className="bt-search-input" onClick={excuteSearchText}>
            <img src="/img/table/btn_search.png" alt="" />
          </Button>
        </div>
      </div>
      {/* 필터 영역 */}
      {Object.keys(filterOption).length > 0 && (
        <TableFilter
          filterOption={filterOption}
          filter={filter}
          searchOption={searchOption}
          changeParam={changeParam}
          initFilter={initFilter}
          filterList={filterList}
          setFilterList={setFilterList}
        />
      )}
      {/* 필터 해제 */}
      <Tooltip
        title={formatMessage({ id: 'Filter_6', defaultMessage: '필터 헤제' })}
        placement="bottom"
      >
        <Button className="bt-filter-init" onClick={initFilter}>
          <span>
            <img
              src={
                Object.keys(filter).length
                  ? '/img/table/btn_filter_active.png'
                  : '/img/table/btn_filter.png'
              }
              alt=""
            />
          </span>
        </Button>
      </Tooltip>
      {/* 페이지 영역 */}
      <div className="pageSize-area">
        <div className="pageSize-status">{`${startCount}-${endCount} / ${totalCount}`}</div>
        <div className="dropdown-arrow-area">
          <img
            className="pageSize-dropdown-image"
            src="/img/table/selectbox_number_arrow.png"
            alt=""
          />
        </div>
        <Dropdown
          className="pageSize-dropdown text-center"
          overlay={pageSizeList}
          trigger={['click']}
        >
          <span>{pageSize}</span>
        </Dropdown>
      </div>
    </div>
  );
}
