import { useIntl } from 'react-intl';
import { commaNumber, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';

interface dashboardProps {
  licenseData: any;
  licenseType: number;
}

function DashboardLicense({ licenseData, licenseType }: dashboardProps) {
  const { formatMessage } = useIntl();

  // 라이센스 없을 때
  const noLicenseComp = (
    <div className="content-row no-license">
      {formatMessage({ id: 'License_19', defaultMessage: '사용 중인 라이선스가 없습니다.' })}
    </div>
  );
  
  const LicenseString: any = { 
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration: formatMessage({ id: 'License_60', defaultMessage: '[교육]연간 라이선스' }),
    EduDurationTimes: formatMessage({ id: 'License_61', defaultMessage: '[교육]횟수 교육 라이선스' })
  };

  if(Option.isJapan === 1){
    LicenseString.Duration = formatMessage({ id: 'License_63', defaultMessage: '[훈련]기간 라이선스' });
    LicenseString.Times = formatMessage({ id: 'License_64', defaultMessage: '[훈련]횟수 라이선스(무제한)' });
    LicenseString.DurationTimes = formatMessage({ id: 'License_65', defaultMessage: '[훈련]횟수 라이선스' });
    LicenseString.EduDuration = formatMessage({ id: 'License_60', defaultMessage: '[교육]연간 라이선스' });
    LicenseString.EduDurationTimes = formatMessage({ id: 'License_61', defaultMessage: '[교육]횟수 라이선스' });
  }

  return (
    <div className="dashboard-license">
      <div className="content-title">
        {licenseType === 8 && LicenseString.DurationTimes}
        {(licenseType === 1 || licenseType === 2) && LicenseString.Duration}
        {licenseType === 3 &&
          formatMessage({ id: 'License_20', defaultMessage: '유지보수 라이선스' })}
        {licenseType === 4 && LicenseString.Times}
      </div>

      <div className="content">
        {licenseData && Object.keys(licenseData).length > 0 ? (
          <>
            {licenseType === 3 && (
              <>
                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'License_1', defaultMessage: '라이선스' })}
                  </div>
                  <div className="content-value">
                    {licenseType === 3 &&
                      formatMessage({ id: 'License_20', defaultMessage: '유지보수 라이선스' })}
                  </div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'License_22', defaultMessage: '고객사' })}
                  </div>
                  <div className="content-value">{licenseData.licenseHostName}</div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'Date_13', defaultMessage: '만료일자' })}
                  </div>
                  <div className="content-value">
                    {timeFormatFromUTCEpoch(licenseData.licenseEndDate, 1)}
                  </div>
                </div>
              </>
            )}

            {licenseType !== 3 && (
              <>
                <div className="content-row">
                  <div className="license-info">{`${licenseData.serviceName} / ${formatMessage({
                    id: 'License_1',
                    defaultMessage: '라이선스',
                  })} No. ${licenseData.licenseNo}`}</div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'License_18', defaultMessage: '라이선스 타입' })}
                  </div>
                  <div className="content-value">
                    {licenseType === 1 &&
                      formatMessage({ id: 'License_15', defaultMessage: '영구 라이선스' })}
                    {licenseType === 2 && LicenseString.Duration}
                    {licenseType === 4 && LicenseString.Times}
                    {licenseType === 8 && LicenseString.DurationTimes}
                  </div>
                </div>

                {(licenseType === 8) &&(
                  <>
                  <div className="content-row">
                    <div className="content-text">
                      {formatMessage({ id: 'Date_24', defaultMessage: '유효기간' })}
                    </div>
                    <div className="content-value">
                      {timeFormatFromUTCEpoch(
                          licenseData.startEpoch,
                          3,
                        )} ~ {timeFormatFromUTCEpoch(licenseData.endEpoch, 3)}
                  </div>
                  </div>
                  </>
                )}
                <div className="content-row">
                  
                  <div className="content-text">
                    {(licenseType === 1 || licenseType === 2) &&
                      formatMessage({ id: 'Date_24', defaultMessage: '유효기간' })}
                    {(licenseType === 4 || licenseType === 8) &&
                      formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' })}
                  </div>
                  <div className="content-value">
                    {licenseType === 1 &&
                      formatMessage({ id: 'License_15', defaultMessage: '영구 라이선스' })}
                    {licenseType === 2 &&
                      `${timeFormatFromUTCEpoch(
                        licenseData.startEpoch,
                        3,
                      )} ~ ${timeFormatFromUTCEpoch(licenseData.endEpoch, 3)}`}
                    {(licenseType === 4 || licenseType === 8)&&
                      `${licenseData.examUsedCount} / ${licenseData.examLimitCount}${formatMessage({
                        id: 'License_13',
                        defaultMessage: '회',
                      })}`}
                  </div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'License_16', defaultMessage: '수량' })}
                  </div>
                  <div className="content-value">
                    {(licenseType === 4 || licenseType === 8)
                      ? `${commaNumber(licenseData.licenseCount)}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`
                      : `${licenseData.targetUsedCount} / ${commaNumber(
                          licenseData.licenseCount,
                        )}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`}
                  </div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'License_21', defaultMessage: '신청자' })}
                  </div>
                  <div className="content-value">
                    {licenseData.orderUserName && licenseData.orderUserEmail
                      ? `${licenseData.orderUserName} (${licenseData.orderUserEmail})`
                      : formatMessage({ id: 'Name_6', defaultMessage: '관리자' })}
                  </div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'Date_14', defaultMessage: '신청일자' })}
                  </div>
                  <div className="content-value">
                    {timeFormatFromUTCEpoch(licenseData.regEpoch)}
                  </div>
                </div>

                <div className="content-row">
                  <div className="content-text">
                    {formatMessage({ id: 'Date_15', defaultMessage: '발급일자' })}
                  </div>
                  <div className="content-value">
                    {timeFormatFromUTCEpoch(licenseData.issueEpoch)}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          noLicenseComp
        )}
      </div>
    </div>
  );
}
export default DashboardLicense;
