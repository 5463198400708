import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'declare/SET_INITIAL';
const GET_DASHBOARD = 'declare/GET_DASHBOARD';

// Actions
// 대시보드 조회
export const getDashboard = (params?: { [key: string]: any }) =>
  actionFormat(GET_DASHBOARD, null, 'get', `${apiUrl}Dashboard`);

// initial state
interface stateType {
  data: Array<string>;
  buildLicenseData: any;
  statisticsData: any;
  infectionTarget: Array<any>;
  infectionTag: Array<any>;
  notice: any;
}

const initialState: stateType = {
  data: [],
  buildLicenseData: {},
  statisticsData: {},
  infectionTarget: [],
  infectionTag: [],
  notice: {},
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_DASHBOARD:
      if (action.payload) {
        const {
          list,
          statistic,
          licenseHostName,
          licenseEndDate,
          infectionTarget,
          infectionTag,
          notice,
        } = action.payload.data;
        state.data = list;
        state.buildLicenseData = {
          licenseHostName: licenseHostName,
          licenseEndDate: licenseEndDate,
        };
        state.statisticsData = statistic;
        state.infectionTarget = infectionTarget;
        state.infectionTag = infectionTag;
        state.notice = notice;
      }
      return state;
    default:
      return state;
  }
}
