import { Option } from './commonValues';

// lang 폴더에 있는 json 파일들 취합하여 하나의 json으로 만듦
const langRequire = require.context('lang', false, /.json/);
const locale: { [key: string]: any } = {};
langRequire.keys().forEach((key) => {
  const langKey = key.substring(2, key.length - 5);
  const langMessage = langRequire(key);
  locale[langKey] = langMessage;
});

const localStorageLang = localStorage.getItem('language');
const browserLangCode =
  localStorageLang || (navigator.languages && navigator.languages[0]) || navigator.language;
const language = browserLangCode.toLowerCase().split(/[_-]+/)[0];
const lang = localStorageLang
  ? locale[localStorageLang]
  : locale[language] || locale[browserLangCode] || locale.ko;

// 이메일
export const RegExpEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i);
// 공백 처리 (2개 이상의 공백)
export const RegExpBlankDup = new RegExp(/\s{2,}/);
// 공백 처리 (시작 공백)
export const RegExpBlankStart = new RegExp(/^\s/);
// 공백 처리 (끝 공백)
export const RegExpBlankEnd = new RegExp(/\s$/);
// 문자열 및 공백 허용(전각 스페이스도 허용)
// export const RegExpDefaultBlank = new RegExp(
//   /[^ㄱ-ㅎㅏ-ㅣ가-힣一-龯豈-龎ぁ-ゟ々－ァ-ヿ゛゜ーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９&()_\-/. 　]/,
// );
// 연락처
export const RegExpPhone = new RegExp(/[^0-9 \-+]/);
// 사용자 비밀번호 입력 제한(회원가입, 비밀번호 변경)
export const RegExpUserPasswd = /^[a-zA-Z0-9!@#$%^&+=]*$/;

// 필수
export const required = (value: any, message?: any) => {
  const hint = message || lang.Vali_14;
  return !value ? hint : undefined;
};

// 입력 범위 지정
export const range = (value: any, min: any, max: any) => {
  const message = `${lang.Vali_43}: [${min > 0 ? min : ''}~${max} ${lang.Vali_44}]`;
  return String(value).length < min || String(value).length > max ? message : undefined;
};

// 이메일
export const email = (value: any) => {
  if (range(value, 0, 320)) {
    return range(value, 0, 320);
  }
  return value && !RegExpEmail.test(value) ? lang.Vali_9 : undefined;
};

// 비밀번호
export const password = (value: any) => {
  let message;
  // 알파벳 포함
  if (1 & value.bit) {
    message = lang.Vali_23;
  }
  // 숫자 포함
  else if (2 & value.bit) {
    message = lang.Vali_24;
  }
  // 특수문자 포함
  else if (4 & value.bit) {
    message = lang.Vali_25;
  }
  // 최소 길이
  else if (8 & value.bit) {
    message = `${lang.Vali_1} ${value.detail['8']}${lang.Vali_27}`;
  }
  // 대문자 포함
  else if (16 & value.bit) {
    message = lang.Vali_28;
  }
  // 동일 문자 제한
  else if (32 & value.bit) {
    message = Option.isJapan 
    ? `${lang.Vali_29}${value.detail['32']}${lang.Vali_51}`
    : `${lang.Vali_29} ${value.detail['32']}${lang.Vali_31}${lang.Vali_51}`;
  }
  // 연속된 문자 제한
  else if (64 & value.bit) {
    message = Option.isJapan 
    ? `${value.detail['64']}${lang.Vali_52}`
    : `${lang.Vali_30} ${value.detail['64']}${lang.Vali_31}${lang.Vali_52}`;
  }
  // 아이디 포함
  else if (128 & value.bit) {
    message = lang.Vali_32;
  }

  return message;
};

export const gsPassword = (value: any) => {
  if (Option.isGs === 1 && value) {
    if (range(value, 8, 15)) {
      return range(value, 8, 15);
    }

    if (!value.match(new RegExp(/[a-zA-z]/))) {
      return lang.Vali_23;
    }

    if (!value.match(new RegExp(/[0-9]/))) {
      return lang.Vali_24;
    }

    if (!value.match(new RegExp(/[!@#$%^&+=]/))) {
      return lang.Vali_25;
    }
  }
  return undefined;
};

// 공백()
export const blank = (value: any) => {
  if (value.match(RegExpBlankDup)) {
    return lang.Vali_36;
  }
  if (value.match(RegExpBlankStart)) {
    return lang.Vali_37;
  }
  if (value.match(RegExpBlankEnd)) {
    return lang.Vali_38;
  }
  return undefined;
};

// 사용자명, 서비스명
export const name = (value: any) => {
  if (blank(value)) {
    return blank(value);
  }

  if (range(value, 0, 50)) {
    return range(value, 0, 50);
  }
  return undefined;
  // const fail = value.match(RegExpDefaultBlank);
  // return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

// 연락처
export const userPhone = (value: any) => {
  if (blank(value)) {
    return blank(value);
  }

  if (range(value, 0, 15)) {
    return range(value, 0, 15);
  }

  const fail = value.match(RegExpPhone);
  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

export const RegExpID = new RegExp(/[^a-zA-Z0-9]/);
export const mailId = (value: string) => {
  if (!value.match(new RegExp(/[a-zA-z]/))) {
    return lang.Vali_23;
  }

  const fail = value.match(RegExpID);
  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

export const RegExpPW = new RegExp(/[^a-zA-Z0-9 ,.()[]=+!@#$%\^&\*:;'"\/<>?~_-\\]/);
export const mailPassword = (value: string) => {
  const fail = value.match(RegExpPW);
  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

export const minValue = (value: number, min: number) =>
  value && value < min ? `${lang.Vali_39}: [ ${min} ]` : undefined;
export const maxValue = (value: number, max: number) =>
  value && value > max ? `${lang.Vali_40}: [ ${max} ]` : undefined;

// 접근 허용 IP
export const accessAllowIp = (value: any) => {
  if (typeof value !== 'undefined' && !!value) {
    try {
      const ipData = JSON.parse(value);
      let isNoArray = false;
      Object.values(ipData).map((data) => {
        if (!Array.isArray(data)) {
          isNoArray = true;
        }
        return undefined;
      });

      if (isNoArray) {
        return lang.Vali_47;
      }
      return undefined;
    } catch (e) {
      return lang.Vali_48;
    }
  }
  return undefined;
};

// 시스템 훈련 URL
export const examSystemUrl = (value: any) => {
  if (typeof value !== 'undefined' && !!value) {
    // 도메인, IP 입력 허용
    const regexp = /^[[]("(http(s?):\/\/)[a-zA-Z0-9\-.]+(")[,\s]*)+[\]]$/g;
    const success = value.match(regexp);
    if (!success) {
      return lang.Vali_54;
    }
    return undefined;
  }
  return undefined;
};

// 대상자 연동 계정 설정 값 체크
export const userAccount = (value: any) => {
  if (typeof value !== 'undefined' && !!value) {
    try {
      const json = JSON.parse(value);

      if (json) {
        return undefined;
      }
      return lang.Vali_56;
    } catch (e) {
      return lang.Vali_56;
    }
  }
  return undefined;
};

// 숫자
export const number = (value: string) =>
  value && Number.isNaN(Number(value)) ? lang.Vali_6 : undefined;

// 윈도우에서 사용 가능한 파일명 체크
export const windowsFileName = (value: string) => {
  if (typeof value !== 'undefined' && !!value) {
    if (range(value, 0, 200)) {
      return range(value, 0, 200);
    }

    const fail = value.match(/[:/%*?:|"<>]/);
    return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
  }
  return undefined;
};

// 압축 비밀번호
export const compressPW = (value: string) => {
  const fail = value.match(RegExpID);
  if (fail && fail[0] === ' ') {
    return lang.Vali_8;
  }

  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

// 첨부파일 원본파일명
export const attachName = (value: string) => {
  if (typeof value !== 'undefined' && !!value) {
    const extensionIndex = value.lastIndexOf('.');
    const pureFileName = extensionIndex > -1 ? value.slice(0, extensionIndex) : value;
    if (pureFileName.match(/\d+$/)) {
      return lang.Vali_55;
    }
  }
  return undefined;
};

// 템플릿명
export const templateName = (value: any) => {
  if (range(value, 0, 50)) {
    return range(value, 0, 50);
  }
  const fail = value.match(
    /[^ㄱ-ㅎㅏ-ㅣ가-힣一-龯豈-龎ぁ-ゟ々－ァ-ヿ゛゜ーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９ ,.()[]=+!@#$%\^&\*:;'"\/<>?~_-\\]/,
  );

  if (fail && fail[0] === ' ') {
    return lang.Vali_8;
  }

  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

// 교육 비밀번호 재설정
export const eduPasswd = (value: any) => {
  const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^*=+]).{8,20}$/;
  return !regex.test(value) ? lang.Vali_56 : undefined;
};

// 영숫자 조합
export const engNumber = (value: any) => {
  if (!value.match(new RegExp(/^[a-zA-z]+[0-9]+/))) {
    return lang.Vali_57;
  }
  return undefined;
};

// 훈련명
export const examName = (value: any) => {
  const fail = value.match(
    /[^ㄱ-ㅎㅏ-ㅣ가-힣一-龯豈-龎ぁ-ゟ々－ァ-ヿ゛゜ーa-zA-Z0-9 ａ-ｚＡ-Ｚ０-９ ()_-]/,
  );

  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

// SAXA전용 훈련명
export const saxaExamName = (value: any) => {
  const fail = value.match(
    /[=+[\]!@#$%^&:;'"/<>?~]/,// examName에서 허용하는 것들은 허용하도록 '()_-'
  );

  return fail ? `特殊文字は使用できません。` : examName(value);
}

// 소속, 직급
export const targetPosition = (value: any) => {
  const fail = value.match(
    /[^ㄱ-ㅎㅏ-ㅣ가-힣一-龯豈-龎ぁ-ゟ々－ァ-ヿ゛゜ーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９&()_\-./\s]/,
  );

  return fail ? `${lang.Vali_10}: [ ${fail[0]} ]` : undefined;
};

// 판매점ID
export const storeId = (value: any) => {
  if (!value.match(new RegExp(/^(sax)\d{5}$/))) {
    return lang.Vali_56;
  }

  return undefined;
};

// SMTP port 제한
export const port = (value: any, min: number, max: number) => {
  const message = `${lang.Vali_43}: [${min}~${max}]`;
  return Number(value) < min || Number(value) > max ? message : undefined;
};