import { Redirect, Route, useRouteMatch, Switch, useLocation, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Menu } from 'antd';
import {
  UserOutlined,
  DesktopOutlined,
  VideoCameraOutlined,
  AlertOutlined,
  ShareAltOutlined,
  ScheduleOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Option } from 'utils/commonValues';
import AutoRoute from 'components/common/AutoRoute';
import './Mypage.scss';

// import mailImage from 'img/setting/mail.png';

export const authLevel = 1;
function Mypage(props: any) {
  const { formatMessage } = useIntl();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const userData = useSelector((state: any) => state.user.user);
  const { isJapan, customer } = Option;

  let mailAddress = 'mudfix@jiran.com';
  if (isJapan) {
    mailAddress = 'support-m@jsecurity.co.jp';
  } else if (customer === 'hyundai') {
    mailAddress = 'admin@hyundai-autoever.com';
  }

  let activeLink = 'profile';
  if (pathname) {
    [activeLink] = pathname.split('/').slice(-1);
  }

  // 경로 접근 제한
  let authRoute = true;
  if (pathname === '/mypage/profile' || pathname === '/mypage/share') {
    // 서비스 공유 시 본인 계정이 아닐 경우 내정보 및 서비스공유 접근 불가
    if (userData?.serviceNo !== userData?.originalServiceNo) {
      authRoute = false;
    }
  }

  return (
    <div className="common-content-layout mypage">
      <div className="common-left-side">
        <Menu className="template-side-menu" selectedKeys={[activeLink]}>
          {userData?.serviceNo === userData?.originalServiceNo && (
            <Menu.Item
              key="profile"
              className="template-side-menu-item"
              onClick={() => props.history.push('/mypage/profile')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                <UserOutlined style={{ fontSize: '19px' }} />
                {formatMessage({ id: 'MyPage_1', defaultMessage: '내 정보' })}
              </div>
            </Menu.Item>
          )}
          <Menu.Item
            key="smtp"
            className="template-side-menu-item"
            onClick={() => props.history.push('/mypage/smtp')}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <DesktopOutlined style={{ fontSize: '19px' }} />
              SMTP
            </div>
          </Menu.Item>
          {Option.isEdu === 1 && (
            <Menu.Item
              key="edu"
              className="template-side-menu-item"
              onClick={() => props.history.push('/mypage/edu')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                <VideoCameraOutlined style={{ fontSize: '19px' }} />
                {formatMessage({ id: 'MyPage_2', defaultMessage: '교육설정' })}
              </div>
            </Menu.Item>
          )}
          {Option.isDeclare === 1 && (
            <Menu.Item
              key="declare"
              className="template-side-menu-item"
              onClick={() => props.history.push('/mypage/declare')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                <AlertOutlined style={{ fontSize: '19px' }} />
                {formatMessage({ id: 'MyPage_3', defaultMessage: '신고설정' })}
              </div>
            </Menu.Item>
          )}
          {Option.customer === 'saxa' && (
            <Menu.Item
              key="notification"
              className="template-side-menu-item"
              onClick={() => props.history.push('/mypage/notification')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                <NotificationOutlined style={{ fontSize: '19px' }} />
                {formatMessage({ id: 'MyPage_9', defaultMessage: '알림설정' })}
              </div>
            </Menu.Item>
          )}
          <Menu.Item
            key="license"
            className="template-side-menu-item"
            onClick={() => props.history.push('/mypage/license')}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <ScheduleOutlined style={{ fontSize: '19px' }} />
              {formatMessage({ id: 'License_1', defaultMessage: '라이센스' })}
            </div>
          </Menu.Item>
          {userData?.serviceNo === userData?.originalServiceNo && (
            <Menu.Item
              key="share"
              className="template-side-menu-item"
              onClick={() => props.history.push('/mypage/share')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                <ShareAltOutlined style={{ fontSize: '19px' }} />
                {formatMessage({ id: 'ServiceShare_1', defaultMessage: '서비스 공유' })}
              </div>
            </Menu.Item>
          )}
        </Menu>

        {Option.customer !== 'saxa' && (
          <div className="support-box">
            <div className="support-title">
              {formatMessage({ id: 'Faq_83', defaultMessage: '문의하기' })}
            </div>
            <a href={`mailto:${mailAddress}`}>
              <img src="/img/setting/mail.png" alt="icon" className="brand-color" />
              {mailAddress}
            </a>
          </div>
        )}
      </div>
      <div className="common-content">
        <Switch>
          {authRoute && <Route path={`${url}/:name`} component={AutoRoute} />}
          <Redirect
            path="*"
            to={
              userData?.serviceNo === userData?.originalServiceNo
                ? '/mypage/profile'
                : '/mypage/smtp'
            }
          />
        </Switch>
      </div>
    </div>
  );
}
export default withRouter(Mypage);
