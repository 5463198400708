import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  getExamSummary,
  putExamSummary,
  changeSummaryState,
  putExamSuspend,
  deleteExamSummary,
  putExamResend,
  changeListState,
  changeRandomListState,
} from 'store/exam';
import { saveForm, initForm } from 'store/form';
import ExamSidebarItem from 'components/branch/exam/ExamSidebarItem';
import StartExamStep2 from 'components/branch/exam/StartExamStep2';
import ModalTemplate from 'components/common/ModalTemplate';
import Loading from 'components/common/Loading';
import { openNotification, timeFormatFromUTCEpoch, epochFromDate } from 'utils/commonFunctions';
import StartExam from './StartExam';

// import allImage from 'img/exam/exam_all.png';
// import listOpenImage from 'img/exam/list_open.png';
// import listCloseImage from 'img/exam/list_close.png';
// import searchImage from 'img/exam/search.png';
// import cancelImage from 'img/exam/search_cancel.png';

function ExamSidebar() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const startExamResult = useSelector((state: any) => state.startExam.result.success, shallowEqual);
  const examSummary = useSelector((state: any) => {
    return {
      data: state.exam.examSummary.data,
      dataByExamNo: state.exam.examSummary.dataByExamNo,
      param: state.exam.examSummary.param,
      needReload: state.exam.examSummary.needReload,
      isAllOpen: state.exam.examSummary.isAllOpen,
      openList: state.exam.examSummary.openList,
      selectedExamNo: state.exam.examSummary.selectedExamNo,
      selectedRandomGroupNo: state.exam.examSummary.selectedRandomGroupNo,
    };
  }, shallowEqual);
  const formStoreData = useSelector((state: any) => state.form.formStore, shallowEqual);

  const { register, errors, control, handleSubmit, setError } = useForm({
    mode: 'all',
  });
  // 로딩
  const [loading, setLoading] = useState(false);
  // 검색 버튼/창 토글
  const [searchMode, setSearchMode] = useState(false);
  // 검색어 필터
  const [searchText, setSearchText] = useState('');
  // 모달 토글
  const [openModalTarget, setOpenModalTarget] = useState<any>({ target: '', examData: {} });
  const [editValues, setEditValues] = useState({});
  const history = useHistory();

  useEffect(() => {
    // 파라미터 변경, 훈련 실시 후 훈련 정보 재조회
    onGetExamSummary();

    return () => {
      // 훈련결과 페이지 벗어날 때 전체 훈련으로 설정
      dispatch(changeSummaryState({ key: 'selectedExamNo', value: 'all' }));
    };
  }, [examSummary.param, startExamResult]);

  useEffect(() => {
    // 훈련 정보 재조회
    if (examSummary.needReload) {
      onGetExamSummary();
    }
  }, [examSummary.needReload]);

  // 훈련 요약 정보 조회
  const onGetExamSummary = async () => {
    setLoading(true);
    try {
      const params = {
        filter: JSON.stringify(examSummary.param.filter),
      };
      await dispatch(getExamSummary(params));
      setLoading(false);
    } catch (error) {
      console.log('ExamSidebar getExamSummary', error);
    }
  };

  // 훈련 선택
  const onSelectExamSummary = useCallback((target: any) => {
    history.push('/exam/pi');
    if (target === 'all') {
      dispatch(changeSummaryState({ key: 'param', value: { filter: {} } }));
      dispatch(changeListState({ key: 'selectedList', value: [] }));
    }
    // 훈련 선택
    dispatch(changeSummaryState({ key: 'selectedExamNo', value: target }));
    // 랜덤발송 그룹 선택 초기화
    dispatch(changeSummaryState({ key: 'selectedRandomGroupNo', value: 0 }));
    // 훈련 리스트 파라미터 초기화
    dispatch(
      changeListState({
        key: 'param',
        value: { filter: {}, sort: [{ field: 'examNo', order: 'DESC' }], offset: 0, limit: 30 },
      }),
    );
    // 랜덤발송 리스트 파라미터 초기화
    dispatch(
      changeRandomListState({
        key: 'param',
        value: { filter: {}, sort: [{ field: 'examNo', order: 'DESC' }], offset: 0, limit: 30 },
      }),
    );
    dispatch(changeListState({ key: 'selectedList', value: [] }));
  }, []);

  // 랜덤발송 그룹 선택
  const onSelectRandomGroup = (examNo: number) => {
    dispatch(changeSummaryState({ key: 'selectedRandomGroupNo', value: examNo }));
    dispatch(changeSummaryState({ key: 'selectedExamNo', value: 0 }));
  };

  // 훈련 요약 열기/닫기
  const toggleOpen = useCallback(
    (selectedNo: any) => {
      let selectList = [...examSummary.openList];
      const existIndex = selectList.findIndex((examNo: any) => examNo === selectedNo);

      // 훈련별 열기/닫기
      if (selectedNo) {
        if (existIndex > -1) {
          // 이미 열린 항목 닫기
          selectList.splice(existIndex, 1);
        } else {
          // 닫힌 항목 열기
          selectList.push(selectedNo);
        }
      }
      // 전체 열기/닫기
      else {
        if (examSummary.isAllOpen) {
          // 전체 닫기
          selectList = [];
        } else {
          // 전체 열기
          selectList = examSummary.data.map((data: any) => data.examNo);
        }
        dispatch(changeSummaryState({ key: 'isAllOpen', value: !examSummary.isAllOpen }));
      }

      // 열린 리스트 설정
      dispatch(changeSummaryState({ key: 'openList', value: selectList }));
    },
    [examSummary.data, examSummary.openList],
  );

  // 훈련 요약 필터
  const handleSummaryFilter = async (type: string) => {
    const selectedFilter = examSummary.param.filter || {};
    if (selectedFilter[type]) {
      delete selectedFilter[type];
    } else {
      selectedFilter[type] = 1;
    }

    await dispatch(changeSummaryState({ key: 'selectedExamNo', value: 'all' }));
    await dispatch(changeSummaryState({ key: 'param', value: { filter: selectedFilter } }));
  };

  // 필터 검색
  const handleSearch = async () => {
    setSearchMode(true);
    if (searchText) {
      examSummary.param.filter.examName = searchText;
    } else {
      // 검색어 없을 시 examName 파라미터 제거
      delete examSummary.param.filter.examName;
    }

    await dispatch(changeSummaryState({ key: 'selectedExamNo', value: 'all' }));
    await dispatch(
      changeSummaryState({ key: 'param', value: { filter: examSummary.param.filter } }),
    );
  };

  // 필터 검색 취소
  const handleCancelSearch = () => {
    setSearchMode(false);
    setSearchText('');
    delete examSummary.param.filter.examName;
    dispatch(changeSummaryState({ key: 'param', value: { filter: examSummary.param.filter } }));
  };

  // 필터 검색어 입력
  const handleChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  // 필터 메뉴
  const filterMenu = useMemo(
    () => [
      {
        key: 'infection',
        text: formatMessage({ id: 'Exam_6', defaultMessage: '감염이 있는 훈련' }),
      },
      {
        key: 'examEnd',
        text: formatMessage({ id: 'Exam_7', defaultMessage: '완료된 훈련' }),
      },
      {
        key: 'examIng',
        text: formatMessage({ id: 'Exam_8', defaultMessage: '진행 중인 훈련' }),
      },
      {
        key: 'examReserve',
        text: formatMessage({ id: 'Exam_9', defaultMessage: '예약 훈련' }),
      },
    ],
    [],
  );

  // 훈련 상세 상태(메일 발송, 메일 열람 등) 클릭 시 필터 적용
  const onClickDetailRow = useCallback((examNo: any, type: string) => {
    // 현재 훈련 선택 처리
    dispatch(changeSummaryState({ key: 'selectedExamNo', value: examNo }));
    // 훈련 리스트에 필터 적용
    dispatch(changeListState({ key: 'param', value: { filter: { [type]: 1 } } }));
  }, []);

  // 설정 메뉴 클릭 이벤트
  const onClickDropdownMenu = async (examNo: any, target: string) => {
    // 선택한 훈련 정보 저장
    setOpenModalTarget({ target: target, examData: examSummary.dataByExamNo[examNo] });

    if (target === 'sendStop' || target === 'sendRestart') {
      onExamSuspend(examNo, target);
    } else if (target === 'examEdit') {
      const {
        examName,
        examStartEpoch,
        examEndEpoch,
        sendReservePeriod,
        sendReserveStartHour,
        sendReserveEndHour,
      } = examSummary.dataByExamNo[examNo];

      const defalutValue = {
        examName: examName,
        startDate: moment.unix(examStartEpoch),
        endDate: moment.unix(examEndEpoch),
        sendReserveEnable: sendReservePeriod ? '1' : '0',
        sendReserveHour: [sendReserveStartHour, sendReserveEndHour],
        sendPeriod: sendReservePeriod,
      };

      // startExamStep2에 원본 훈련 정보 전달하기 위함
      setEditValues(defalutValue);

      // 훈련 수정일 시 훈련 정보 폼 스토어에 저장
      onSaveForm(defalutValue);
    }
  };

  // 모달 닫기(초기화)
  const onCloseModal = () => {
    setOpenModalTarget({ target: '', examData: {} });
    dispatch(initForm());
  };

  // 우측에 팝업 메시지 띄우기
  const makeNotification = (examName: string, message: string) => {
    const resultMessage = `'${examName}' ${message}`;
    openNotification(resultMessage);
  };

  // 현재 입력 값 폼 스토어에 저장
  const onSaveForm = (newForm: any) => {
    dispatch(saveForm(newForm));
  };

  // 수정 이벤트
  const onExamEdit = async () => {
    try {
      const beforeData = openModalTarget.examData;
      const params: any = {
        examNo: beforeData.examNo,
      };

      // 훈련명 변경 시
      if (beforeData.examName !== formStoreData.examName) {
        params.examName = formStoreData.examName;
      }

      // 시작일자 변경 시
      if (beforeData.examStartEpoch !== epochFromDate(formStoreData.startDate)) {
        params.examStartEpoch = epochFromDate(formStoreData.startDate);
      }

      // 종료일자 변경 시
      if (beforeData.examEndEpoch !== epochFromDate(formStoreData.endDate)) {
        params.examEndEpoch = epochFromDate(formStoreData.endDate);
      }

      // 훈련 메일 나눔 발송 설정 시
      if (formStoreData.sendReserveEnable === '1') {
        if (beforeData.sendReservePeriod !== formStoreData.sendPeriod) {
          params.sendReservePeriod = formStoreData.sendPeriod;
        }

        const [start, end] = formStoreData.sendReserveHour;
        if (beforeData.sendReserveStartHour !== start) {
          params.sendReserveStartHour = start;
        }
        if (beforeData.sendReserveEndHour !== end) {
          params.sendReserveEndHour = end;
        }
      } else if (beforeData.sendReservePeriod) {
        params.sendReserveDelete = 1;
      }

      // 변경된 값이 있을 경우
      if (Object.keys(params).length > 1) {
        setLoading(true);
        const response: any = await dispatch(putExamSummary(params));
        setLoading(false);
        if (response?.data?.list?.length > 0) {
          onCloseModal();
          makeNotification(
            formStoreData.examName,
            formatMessage({ id: 'Exam_19', defaultMessage: '훈련 수정' }),
          );
        } else if (response?.data?.error[0]) {
          if (response?.data?.error[0]?.errorInfo === 'Duplicated Exam') {
            setError('examName', {
              type: 'duplicate',
              message: formatMessage({
                id: 'StartExam_45',
                defaultMessage: '이미 사용중인 훈련명입니다.',
              }),
            });
          } else {
            setError('examName', {
              type: 'examNameError',
              message: response.data.error[0].errInfo,
            });
          }
        }
      } else {
        onCloseModal();
      }
    } catch (error) {
      console.log('ExamSidebar onExamEdit', error);
    }
  };

  // 삭제 이벤트
  const onExamDelete = async () => {
    try {
      // 선택된 훈련이 삭제될 경우 전체 훈련으로 선택 처리
      onSelectExamSummary('all');

      await dispatch(
        deleteExamSummary({ examNoArray: JSON.stringify([openModalTarget.examData.examNo]) }),
      );
      onCloseModal();
      makeNotification(
        openModalTarget.examData.examName,
        formatMessage({ id: 'Exam_20', defaultMessage: '훈련 삭제' }),
      );
    } catch (error) {
      console.log('ExamSidebar onExamDelete', error);
    }
  };

  // 메일 발송 일시정지/재시작
  const onExamSuspend = async (examNo: number, target: string) => {
    try {
      await dispatch(putExamSuspend({ examNo: examNo, isSuspend: target === 'sendStop' ? 1 : 0 }));
      makeNotification(
        examSummary.dataByExamNo[examNo].examName,
        target === 'sendStop'
          ? formatMessage({ id: 'Send_10', defaultMessage: '메일 발송 일시정지' })
          : formatMessage({ id: 'Send_11', defaultMessage: '메일 발송 재시작' }),
      );
    } catch (error) {
      console.log('ExamSidebar onExamSuspend', error);
    }
  };

  // 훈련 즉시 실행/종료
  const onExamNow = async (target: string) => {
    try {
      // 훈련 즉시 실행
      if (target === 'examExecute') {
        await dispatch(putExamSummary({ examNo: openModalTarget.examData.examNo, isStartNow: 1 }));
        onCloseModal();
        makeNotification(
          openModalTarget.examData.examName,
          formatMessage({ id: 'Exam_10', defaultMessage: '훈련 즉시 실행' }),
        );
      } // 훈련 즉시 종료
      else if (target === 'examEnd') {
        await dispatch(putExamSummary({ examNo: openModalTarget.examData.examNo, isEndNow: 1 }));
        onCloseModal();
        makeNotification(
          openModalTarget.examData.examName,
          formatMessage({ id: 'Exam_11', defaultMessage: '훈련 즉시 종료' }),
        );
      }
    } catch (error) {
      console.log('ExamSidebar onExamNow', error);
    }
  };

  // 훈련/실패한 메일 재전송
  const onExamResend = async (target: string) => {
    try {
      await dispatch(putExamResend({ examNo: openModalTarget.examData.examNo, sendType: target }));
      onCloseModal();
      makeNotification(
        openModalTarget.examData.examName,
        target === 'examResend'
          ? formatMessage({ id: 'Resend_3', defaultMessage: '훈련 메일 재전송' })
          : formatMessage({ id: 'Resend_4', defaultMessage: '실패한 메일 재전송' }),
      );
    } catch (error) {
      console.log('ExamSidebar onExamResend', error);
    }
  };

  // 랜덤발송인 훈련은 그룹으로 처리하기 위해 리스트 재배열
  const reorderExam = useMemo(() => {
    let randomExamList: any = [];
    const tempExam: any = [];
    examSummary.data.forEach((data: any) => {
      // 랜덤발송이어도 템플릿 하나만 선택할 시 일반발송과 동일하게 처리
      if (data.randomSend && data.parentExamCount > 0) {
        randomExamList.push(data);

        if (randomExamList.length === data.parentExamCount) {
          tempExam.push(randomExamList);
          randomExamList = [];
        }
      } else {
        tempExam.push(data);
      }
    });
    return tempExam;
  }, [examSummary.data]);

  // 랜덤발송 그룹 관련 상태 초기화
  const randomInit = () => {
    // 파라미터 초기화
    dispatch(
      changeRandomListState({
        key: 'param',
        value: {
          filter: {},
          sort: [{ field: 'examNo', order: 'DESC' }],
          offset: 0,
          limit: 15,
        },
      }),
    );
    // 선택한 대상자 번호 초기화
    dispatch(changeRandomListState({ key: 'selectedList', value: [] }));
  };

  return (
    <div className="summary-list">
      <Loading loading={loading} darkMode />
      <div className="summary-list-header">
        <div
          className={`summary-title-area summary-item ${
            examSummary.selectedExamNo === 'all' ? 'selected' : ''
          }`}
          onClick={() => onSelectExamSummary('all')}
          aria-hidden="true"
        >
          <img src="/img/exam/exam_all.png" alt="all" />
          <span>{formatMessage({ id: 'Exam_5', defaultMessage: '전체 훈련' })}</span>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              toggleOpen(null);
            }}
          >
            {examSummary.isAllOpen ? (
              <img src="/img/exam/list_close.png" alt="close" />
            ) : (
              <img src="/img/exam/list_open.png" alt="open" />
            )}
          </button>
        </div>
        <div className="summary-list-filter">
          <div className="filter-list">
            {/* 훈련 필터 */}
            {filterMenu.map((menu: any) => {
              return (
                <div
                  className={`summary-search-item ${
                    examSummary.param.filter && examSummary.param.filter[menu.key] ? 'active' : ''
                  }`}
                  key={menu.key}
                  onClick={() => handleSummaryFilter(menu.key)}
                  aria-hidden="true"
                >
                  <div className="filter-icon" />
                  <div className="filter-name">{menu.text}</div>
                </div>
              );
            })}
            {/* 검색 */}
            <div
              className={`summary-search-item ${searchMode ? 'active' : ''}`}
              key="examName"
              onClick={() => setSearchMode(true)}
              aria-hidden="true"
            >
              <div className="filter-icon" />
              {searchMode ? (
                <div className="summary-search-text">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleChangeSearch}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSearch();
                    }}
                    placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                  />
                  <div className="summary-search-btns">
                    <img
                      className="search-btn"
                      src="/img/exam/search.png"
                      alt="search"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSearch();
                      }}
                      aria-hidden="true"
                      title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                    />
                    <img
                      className="search-btn"
                      src="/img/exam/search_cancel.png"
                      alt="cancel"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelSearch();
                      }}
                      aria-hidden="true"
                      title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                    />
                  </div>
                </div>
              ) : (
                <div className="filter-name" aria-hidden="true">
                  {formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="summary-list-content">
        {reorderExam.length > 0 ? (
          // examSummary.data.map((data: any) => {
          //   return (
          //     <ExamSidebarItem
          //       summaryInfo={data}
          //       key={data.examNo}
          //       isOpen={examSummary.openList.includes(data.examNo)}
          //       selectedItem={examSummary.selectedExamNo}
          //       toggleOpen={toggleOpen}
          //       onClickItem={onSelectExamSummary}
          //       onClickDetailRow={onClickDetailRow}
          //       onClickDropdownMenu={onClickDropdownMenu}
          //     />
          //   );
          // })
          reorderExam.map((data: any) => {
            if (data.length > 1) {
              return (
                <div
                  className={`random-group ${
                    examSummary.selectedRandomGroupNo === data[data.length - 1].parentsExamNo
                      ? ' selected'
                      : ''
                  }`}
                  key={JSON.stringify(data)}
                  onClick={randomInit}
                  aria-hidden="true"
                >
                  <div
                    className="random-title"
                    onClick={() => {
                      onSelectRandomGroup(data[data.length - 1].parentsExamNo);
                    }}
                    aria-hidden="true"
                  >
                    {`${formatMessage({
                      id: 'StartExam_68',
                      defaultMessage: '랜덤발송',
                    })} ${formatMessage({ id: 'StartExam_70', defaultMessage: '그룹' })}`}
                  </div>
                  {data.map((item: any) => (
                    <ExamSidebarItem
                      summaryInfo={item}
                      key={item.examNo}
                      isOpen={examSummary.openList.includes(item.examNo)}
                      selectedItem={examSummary.selectedExamNo}
                      toggleOpen={toggleOpen}
                      onClickItem={onSelectExamSummary}
                      onClickDetailRow={onClickDetailRow}
                      onClickDropdownMenu={onClickDropdownMenu}
                    />
                  ))}
                </div>
              );
            }

            return (
              <ExamSidebarItem
                summaryInfo={data}
                key={data.examNo}
                isOpen={examSummary.openList.includes(data.examNo)}
                selectedItem={examSummary.selectedExamNo}
                toggleOpen={toggleOpen}
                onClickItem={onSelectExamSummary}
                onClickDetailRow={onClickDetailRow}
                onClickDropdownMenu={onClickDropdownMenu}
              />
            );
          })
        ) : (
          <div className="no-exam-summary">
            {formatMessage({ id: 'Exam_35', defaultMessage: '훈련이 없습니다.' })}
          </div>
        )}
      </div>

      {/* 수정 모달 */}
      {openModalTarget.examData && openModalTarget.target === 'examEdit' && (
        <>
          <ModalTemplate
            className="exam-summary-modal modal-464"
            visible={openModalTarget.target === 'examEdit'}
            title={formatMessage({ id: 'Exam_19', defaultMessage: '훈련 수정' })}
            onOk={handleSubmit(onExamEdit)}
            onCancel={onCloseModal}
            okText={formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
            disabled={Object.keys(errors).length > 0}
          >
            <form autoComplete="off">
              <div className="modal-content summary-edit">
                <StartExamStep2
                  register={register}
                  errors={errors}
                  control={control}
                  onSaveForm={onSaveForm}
                  mode="edit"
                  editValues={editValues}
                />
              </div>
            </form>
          </ModalTemplate>
        </>
      )}

      {/* 삭제 모달 */}
      {openModalTarget.examData && (
        <ModalTemplate
          className="exam-summary-modal modal-464"
          visible={openModalTarget.target === 'examDelete'}
          title={formatMessage({ id: 'Exam_20', defaultMessage: '훈련 삭제' })}
          onOk={onExamDelete}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Exam_21',
              defaultMessage: '삭제한 훈련은 복구할 수 없습니다.',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{openModalTarget.examData.examNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{openModalTarget.examData.examName}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' })}
                </div>
              </li>
              <div>{`${timeFormatFromUTCEpoch(
                openModalTarget.examData.examStartEpoch,
                3,
              )} ~ ${timeFormatFromUTCEpoch(openModalTarget.examData.examEndEpoch, 3)}`}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
                </div>
              </li>
              <div>{timeFormatFromUTCEpoch(openModalTarget.examData.regEpoch)}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 훈련 즉시 실행/종료 모달 */}
      {openModalTarget.examData && (
        <ModalTemplate
          className="exam-summary-modal modal-464"
          visible={openModalTarget.target === 'examExecute' || openModalTarget.target === 'examEnd'}
          title={
            openModalTarget.target === 'examExecute'
              ? formatMessage({ id: 'Exam_10', defaultMessage: '훈련 즉시 실행' })
              : formatMessage({ id: 'Exam_11', defaultMessage: '훈련 즉시 종료' })
          }
          onOk={() => onExamNow(openModalTarget.target)}
          onCancel={onCloseModal}
          okText={
            openModalTarget.target === 'examExecute'
              ? formatMessage({ id: 'Button_9', defaultMessage: '실 행' })
              : formatMessage({ id: 'Button_13', defaultMessage: '종 료' })
          }
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {openModalTarget.target === 'examExecute'
              ? formatMessage({
                  id: 'Exam_23',
                  defaultMessage: '훈련을 즉시 실행 하시겠습니까?',
                })
              : formatMessage({
                  id: 'Exam_24',
                  defaultMessage: '훈련을 즉시 종료 하시겠습니까?',
                })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{openModalTarget.examData.examNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{openModalTarget.examData.examName}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 훈련/실패한 메일 재전송 모달 */}
      {openModalTarget.examData && (
        <ModalTemplate
          className="exam-summary-modal modal-464"
          visible={
            openModalTarget.target === 'examResend' ||
            openModalTarget.target === 'failTargetExamResend'
          }
          title={
            openModalTarget.target === 'examResend'
              ? formatMessage({ id: 'Resend_3', defaultMessage: '훈련 메일 재전송' })
              : formatMessage({ id: 'Resend_4', defaultMessage: '실패한 메일 재전송' })
          }
          onOk={() => onExamResend(openModalTarget.target)}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {openModalTarget.target === 'examResend'
              ? formatMessage({
                  id: 'Resend_10',
                  defaultMessage: '훈련 메일을 다시 보내시겠습니까?',
                })
              : formatMessage({
                  id: 'Resend_11',
                  defaultMessage: '실패한 메일을 다시 보내시겠습니까?',
                })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{openModalTarget.examData.examNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{openModalTarget.examData.examName}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {openModalTarget.target === 'retraining' && (
        <StartExam
          onCloseModal={onCloseModal}
          loadData={openModalTarget.examData}
          isRetraining
        />
      )}
    </div>
  );
}
export default ExamSidebar;
