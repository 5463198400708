import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Popover, Radio, RadioChangeEvent } from 'antd';
import {
  CloseSquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';

function EduPreviewQuiz({ dataInfo }: any) {
  const { formatMessage } = useIntl();
  const [selectedQuiz, setSelectedQuiz]: any = useState(null);
  const [choiceList, setChoiceList]: any = useState([{ 1: '' }, { 2: '' }]);
  const [quizList, setQuizList]: any = useState(dataInfo.quizInfo?.filter((item: any) => !!item.quizNo));
  const { isJapan } = Option;

  const { errors, control, register, handleSubmit, watch, setValue, setError, clearErrors }: any = useForm({
    mode: 'all',
    defaultValues: {
      attachName: dataInfo.attachName || '',
      contentType: dataInfo.contentType || 4,
      fileName: dataInfo.fileName || '',
      filePath: dataInfo.contentType !== 3 && dataInfo.fileInfo?.[0]?.filePath || '',
      insertQuiz: dataInfo.quizInfo?.[0].quizNo ? true : false,
      serviceNo: dataInfo.serviceNo || '',
      isPublic: dataInfo.isPublic?.toString() || '1'
    },
  });
  const {
    errors: quizErrors,
    control: quizControl,
    register: quizRegister,
    watch: quizWatch,
    setValue: quizSetValue,
  }: any = useForm({
    mode: 'all',
    defaultValues: {
      quizType: 1,
      // quizContent: '',
      // quizSolution: '',
      // quizExplain: '',
    },
  });

  // 객관식 보기 항목 onChange 이벤트
  const changeChoiceText = (e: any, key: string) => {
    const tempList = [...choiceList];
    tempList.forEach((item, index): any => {
      if (Object.keys(item)[0] === key) {
        tempList[index][key] = e.target.value;
      }
    });
    setChoiceList(tempList);
  };

  // 퀴즈 목록에서 퀴즈 선택 시 이벤트
  const selectQuiz = (quiz: any) => {
    const { quizContent, quizExplain, quizSolution, quizType } = quiz;
    quizSetValue('quizType', quizType);
    quizSetValue('quizContent', quizContent);
    quizSetValue('quizSolution', quizSolution);
    quizSetValue('quizExplain', quizExplain);

    if (quiz?.extraInfo?.length) {
      setChoiceList(quiz.extraInfo);
      quiz.extraInfo.forEach((choice: any) => {
        quizSetValue(Object.keys(choice)[0], Object.keys(choice)[0] ? choice[Object.keys(choice)[0]] : '');
      });
    }

    setSelectedQuiz(quiz);
  };

  // 퀴즈 유형별 정답
  const quizChoiceList: any = [
    {
      key: 1,
      value: 1,
      text: formatMessage({ id: 'Edu_133', defaultMessage: 'O/X' }),
      solution: (
        <FormSelectField
          name="quizSolution"
          control={quizControl}
          error={quizErrors.quizSolution}
          menuList={[
            { key: 1, value: '1', text: 'O' },
            { key: 2, value: '2', text: 'X' },
          ]}
          value={quizWatch().quizSolution || selectedQuiz?.quizSolution}
          handleOnChange={(name: any, data: any) => quizSetValue(name, data)}
          validation={{ validate: { required: (value: any) => valid.required(value) } }}
          showArrow
          disabled
        />
      ),
    },
    {
      key: 2,
      value: 2,
      text: formatMessage({ id: 'Edu_94', defaultMessage: '객관식' }),
      solution: (
        <div className="choice-box">
          <div className="choice-list">
            <Controller
              control={quizControl}
              name="quizSolution"
              rules={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
              error={quizErrors.quizSolution}
              render={({ onChange }) => (
                <Radio.Group
                  className={quizErrors.quizSolution ? 'warning' : ''}
                  onChange={({ target: { value } }: RadioChangeEvent) => {
                    onChange(value);
                    quizSetValue('quizSolution', value);
                  }}
                  value={quizWatch().quizSolution || selectedQuiz?.quizSolution}
                >
                  {choiceList?.map((item: any) => {
                    const choiceKey = Object.keys(item)[0];
                    const choiceValue = Object.values(item)[0];
                    return (
                      <Radio value={choiceKey} key={choiceKey} disabled>
                        <FormTextField
                          name={choiceKey}
                          register={quizRegister}
                          error={quizErrors[choiceKey]}
                          defaultValue={choiceValue}
                          onChange={(e: any) => changeChoiceText(e, choiceKey)}
                          placeholder={formatMessage({
                            id: 'Edu_97',
                            defaultMessage: '보기를 입력하세요.',
                          })}
                          validation={{
                            validate: {
                              required: (value: any) => valid.required(value),
                            },
                          }}
                          disabled
                        />
                      </Radio>
                    );
                  })}
                </Radio.Group>
              )}
            />
          </div>
        </div>
      ),
    },
    {
      key: 3,
      value: 3,
      text: formatMessage({ id: 'Edu_95', defaultMessage: '단답형' }),
      solution: (
        <FormTextField
          name="quizSolution"
          register={quizRegister}
          error={quizErrors.quizSolution}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              range: (value: any) => valid.range(value, 0, 30),
            },
          }}
          defaultValue={selectedQuiz?.quizSolution}
          disabled
        />
      ),
      helpMessage: (
        quizWatch().quizType === 3 &&
        <Popover
          overlayClassName="edu-template-popover"
          placement="right"
          content={
            <div>
              <div>· {`${formatMessage({ id: 'Vali_43', defaultMessage: '입력 허용 범위' })}: [~30${formatMessage({ id: 'Vali_44', defaultMessage: '자' })}]`}</div>
              <div>· {formatMessage({ id: 'Edu_128', defaultMessage: "입력 정보가 완전히 일치하는 경우에만 정답이 되므로 입력에 주의해 주십시오." })}</div>
            </div>
          }
        >
          <i className="question-icon">
            <QuestionCircleOutlined />
          </i>
        </Popover>
      )
    },
  ];

  return (
    <div className="edu-preview-quiz">
      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        <div className="input-title">
          {formatMessage({ id: 'Edu_132', defaultMessage: '퀴즈 목록' })}
        </div>
        <div className="video-list-box">
          <ul className="video-list">
            {quizList?.map((quiz: any) => {
              return (
                <li className="video-list-item" key={`${quiz.quizContent}${quiz.quizSolution}`}>
                  <div className="list-info" onClick={() => selectQuiz(quiz)} aria-hidden="true">
                    <span className="video-title">{quiz.quizContent}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="video-add-area mt-15">
          <div className="input-title">
            {formatMessage({ id: 'Edu_91', defaultMessage: '질문' })}
            {isJapan ? '' : '*'}
          </div>
          <Controller
            control={quizControl}
            name="quizContent"
            error={quizErrors.quizContent}
            rules={{
              validate: {
                required: (value: any) => valid.required(value),
                range: (value: any) => valid.range(value, 0, 5000),
              },
            }}
            render={({ onChange, onBlur, value }: any) => (
              <textarea
                className={`textarea-input${quizErrors.quizContent ? ' warning' : ''}`}
                cols={100}
                rows={2}
                onChange={onChange}
                value={quizWatch().quizContent}
                disabled
              />
            )}
          />

            {/* 퀴즈 유형 */}
            <div className="edu-item">
              <div className="input-title">
                {formatMessage({ id: 'Edu_90', defaultMessage: '퀴즈 유형' })}
                {isJapan ? '' : '*'}
              </div>
              <FormSelectField
                name="quizType"
                control={quizControl}
                error={quizErrors.quizType}
                menuList={[
                  {
                    key: 1,
                    value: 1,
                    text: formatMessage({ id: 'Edu_133', defaultMessage: 'O/X' }),
                  },
                  {
                    key: 2,
                    value: 2,
                    text: formatMessage({ id: 'Edu_94', defaultMessage: '객관식' }),
                  },
                  {
                    key: 3,
                    value: 3,
                    text: formatMessage({ id: 'Edu_95', defaultMessage: '단답형' }),
                  },
                ]}
                handleOnChange={(name: any, data: any) => setValue('quizType', data)}
                value={quizWatch().quizType}
                showArrow
                disabled
              />
            </div>
          <div className="input-title mt-10">
            {formatMessage({ id: 'Edu_35', defaultMessage: '정답' })}*
            {/* {quizChoiceList[quizWatch().quizType - 1 || 0]?.helpMessage || <></>} */}
          </div>
          <div>{quizChoiceList[quizWatch().quizType - 1 || 0]?.solution}</div>

          <div className="text-field-title">
            {formatMessage({ id: 'Edu_92', defaultMessage: '문제 해설' })}*
          </div>
          <Controller
            control={quizControl}
            name="quizExplain"
            rules={{
              validate: {
                required: (value: any) => valid.required(value),
                range: (value: any) => valid.range(value, 0, 5000),
              },
            }}
            render={({ onChange }: any) => (
              <textarea
                className={`textarea-input${quizErrors.quizExplain ? ' warning' : ''}`}
                cols={100}
                rows={2}
                onChange={onChange}
                value={quizWatch().quizExplain}
                defaultValue={quizWatch().quizExplain}
                disabled
              />            
            )}
          />
        </div>
      </form>
    </div>
  );
}

export default EduPreviewQuiz;