import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';
import * as valid from 'utils/validation';
import { otpLogin, resendOtpEmail } from 'store/login';
import { setUser } from 'store/user';
import { fitTwoDigit, nowEpoch } from 'utils/commonFunctions';
import { changeOptions, Option } from 'utils/commonValues';

function Otp() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [sendResult, setSendResult] = useState(0);
  const [time, setTime] = useState(0);
  const { handleSubmit, register, watch, setError, errors } = useForm();
  const { formatMessage } = useIntl();
  const history = useHistory();

  useEffect(() => {
    // 페이지 새로고침 시(checkLogin에서 초기화), 현재 페이지 벗어날 시(unmount) 기본 로그인 화면으로 이동
    if (!localStorage.getItem('otpUser')) {
      history.push('/login');
    }

    // 1초마다 실행됨(남은 시간 1초씩 감소시킴)
    const otpTimeInterval = setInterval(() => {
      const otpExpiredTime = parseInt(localStorage.getItem('otpTime') || '0', 10);
      if (otpExpiredTime > nowEpoch()) {
        setTime(otpExpiredTime - nowEpoch());
      } else {
        setTime(0);
      }
    }, 1000);

    return () => {
      clearInterval(otpTimeInterval);
    };
  }, [sendResult]);

  // 인증하기
  const onOtpLogin = async (formData: any) => {
    const userEmail = localStorage.getItem('otpUser');
    if (!userEmail) return;

    try {
      setLoading(true);

      const params = {
        userEmail: userEmail,
        userOtp: formData?.userOtp,
      };
      const response: { [key: string]: any; } = await dispatch(otpLogin(params));
      if (response?.data?.list) {
        const {
          loginOtpResult,
          loginMessage,
          userData,
          sessionTimeout,
          serviceNo,
        } = response.data.list;

        setLoading(false);

        if (loginOtpResult === 1) {
          // 로그인 성공
          const {
            isSaas,
            isGs,
            isJapan,
            isDeclare,
            isTagTree,
            userData,
            customer,
            serviceName,
          } = response.data.list;

          // config 옵션 설정
          changeOptions({
            isSaas: isSaas,
            isGs: isGs,
            isJapan: isJapan,
            isEdu: userData?.isEdu ? 1 : 0,
            isDeclare: isDeclare,
            isTagTree: isTagTree,
            customer: customer,
            serviceName: serviceName,
          });

          userData.serviceNo = serviceNo;
          await dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));

          localStorage.setItem('loginUser', userData.userEmail);
          sessionStorage.setItem('accessTime', nowEpoch());

          // 로그인 횟수 초기화
          localStorage.setItem('loginCnt', '0');

          // 언어 설정
          if (localStorage.getItem('language') !== userData.lang) {
            localStorage.setItem('language', userData.lang);
            window.location.reload();
          }
          history.push('/');
        } else if (loginOtpResult === 2) {
          // 로그인 실패
          setError('userOtp', {
            type: 'fail',
            message: loginMessage,
          });
        } else if (loginOtpResult === 4) {
          // ID or 비밀번호 불일치
          setError('userOtp', {
            type: 'not matched',
            message: loginMessage,
          });
        } else if (loginOtpResult === 8) {
          // 기간만료
          setError('userOtp', {
            type: 'expired',
            message: loginMessage,
          });
        }
      }
    } catch (error) {
      console.log('onOtpLogin', error);
    }
  };

  // 인증 메일 재전송
  const onResendOtpEmail = async () => {
    const userEmail = localStorage.getItem('otpUser');
    if (!userEmail) return;

    setResendLoading(true);
    try {
      const params = {
        userEmail: userEmail,
      };
      const response: { [key: string]: any; } = await dispatch(resendOtpEmail(params));
      setResendLoading(false);

      if (response?.data?.list) {
        const { isOk } = response.data.list;
        if (isOk) {
          // 재전송 성공
          setSendResult(1);
          localStorage.setItem('otpTime', nowEpoch() + 1798);
        } else {
          // 재전송 실패
          setSendResult(2);
        }
      }
    } catch (error) {
      console.log('onResendOtpEmail', error);
    }
  };

  // input 값 변경 시 메일 재전송 메시지 초기화
  const onChangeForm = () => {
    if (sendResult) {
      setSendResult(0);
    }
  };

  return (
    <div className="otp-page login-wrapper column">
      <div>
        <img className="logo-white" src="/img/logo/mf_logo_white.png" alt="logo" />
      </div>
      <div className="content-box">
        <div className="info-text">
          <div>
            <div className="main-text">
              {formatMessage({ id: 'Login_12', defaultMessage: '이메일 추가 인증' })}
            </div>
            <div className="sub-text">
              {formatMessage({
                id: 'Login_13',
                defaultMessage: '로그인 계정 이메일로 발송된 인증 번호를 입력해주세요.',
              })}
            </div>
            {/* 인증 남은 시간 */}
            <div className="otp-remain-time">{`${formatMessage({
              id: 'Time_5',
              defaultMessage: '남은 시간',
            })}: ${fitTwoDigit(Math.floor((time % 3600) / 60)) || '00'}${formatMessage({
              id: 'Time_2',
              defaultMessage: '분',
            })} ${fitTwoDigit(Math.floor(time % 60)) || '00'}${formatMessage({
              id: 'Time_3',
              defaultMessage: '초',
            })}`}</div>
          </div>
        </div>

        <form
          className="otp-form"
          onSubmit={handleSubmit(onOtpLogin)}
          onChange={onChangeForm}
          autoComplete="off"
        >
          <input
            name="userOtp"
            className="underline-input"
            placeholder={formatMessage({ id: 'Login_14', defaultMessage: '인증 번호' })}
            ref={register({
              validate: {
                required: (value) => valid.required(value),
              },
            })}
          />
          <div className="error-message">{errors.userOtp && errors.userOtp.message}</div>

          <div className="button-box">
            <button
              className="login-btn"
              type="submit"
              style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
            >
              {formatMessage({ id: 'Login_15', defaultMessage: '인증하기' })}
              {loading && (
                <span className="button-loading">
                  <LoadingOutlined />
                </span>
              )}
            </button>
            <button
              className="login-btn"
              //   type="submit"
              type="button"
              onClick={onResendOtpEmail}
              style={{ background: 'url(/img/login/grey_button.png) no-repeat center' }}
            >
              {formatMessage({ id: 'NoActive_5', defaultMessage: '인증메일 재전송' })}
              {resendLoading && (
                <span className="button-loading">
                  <LoadingOutlined />
                </span>
              )}
            </button>
          </div>
          {!!sendResult && (
            <div className={`resend-result ${sendResult === 1 ? ' success' : ''}`}>
              {sendResult === 1
                ? formatMessage({ id: 'NoActive_6', defaultMessage: '메일 재전송 성공' })
                : formatMessage({ id: 'NoActive_7', defaultMessage: '메일 재전송 실패' })}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Otp;
