import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Popover, Slider, Tag, Select, Checkbox } from 'antd';
import { ExclamationCircleFilled, CloseOutlined, CloseCircleFilled } from '@ant-design/icons';
import parse from 'html-react-parser';
import * as valid from 'utils/validation';
import FormSelectField from 'components/common/FormSelectField';

// import qmarkImage from 'img/exam/startExam/btn_qmark.png';

function StartExamStep3(props: any) {
  const { errors, control, onSaveForm, errorMessage, setErrorMessage, loadData } = props;
  const { formatMessage } = useIntl();
  const formStoreData = useSelector((state: any) => state.form.formStore);
  const prepareData = useSelector((state: any) => state.startExam.prepare);
  const tagData = useSelector((state: any) => state.startExam.tag);
  const [values, setValues] = useState<any>({
    examTargetRandomPer: formStoreData.examTargetRandomPer || 100,
    isAllTarget: formStoreData.isAllTarget || false,
    examTargetTag: formStoreData.examTargetTag || [],
    exceptExamTargetTag: formStoreData.exceptExamTargetTag || [],
  });
  const { Option } = Select;

  useEffect(() => {
    if (loadData) {
      const isAll = !formStoreData.isAllTarget && formStoreData.examTargetTag ? false : true;
      setValues({ ...values, isAllTarget: isAll });
    }
  }, [loadData]);

  useEffect(() => {
    // 값 변경 시 폼 스토어에 저장
    onSaveForm({ ...values });
  }, [values]);

  const tagRender = (props: any, name: any) => {
    let tagComponent = null;
    const currentTagData = tagData.filter((tag: any) => tag.tagNo === props.value)[0];
    if (currentTagData) {
      const { tagName, color, targetCount, tagNo } = currentTagData;
      tagComponent = (
        <Tag closable className={`color-tag tag-label-${color}`}>
          {`${tagName} - ${targetCount}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`}
          <CloseCircleFilled onClick={() => handleClearTag(name, tagNo)} />
        </Tag>
      );
    }
    return tagComponent;
  };

  // 태그 선택 이벤트
  const handleSelectTag = (name: string, data: any) => {
    setValues({
      ...values,
      [name]: data,
    });

    // 에러 메시지 초기화
    setErrorMessage('');
  };

  // 태그 삭제(X 버튼 클릭) 이벤트
  const handleClearTag = (name: any, exceptTagNo: any) => {
    const selectedValue = values[name].filter((tagNo: any) => tagNo !== exceptTagNo);
    setValues({
      ...values,
      [name]: selectedValue,
    });
    
    // 에러 메시지 초기화
    setErrorMessage('');
  };

  let tagListComponent = null;
  let exceptTagListComponent = null;
  let tagTargetCount = 0;
  const tagOption: any = [];
  const exceptTagOption: any = [];

  // 등록된 태그가 있을 경우
  if (tagData.length > 0) {
    tagData.forEach((tag: any) => {
      // 태그에 할당된 대상자 수 확인
      tagTargetCount += tag.targetCount;
    });

    // 대상자 태그 리스트
    const tagList = tagData.filter(
      (tag: any) =>
        !values.exceptExamTargetTag?.includes(tag.tagNo) &&
        !values.examTargetTag?.includes(tag.tagNo),
    );

    tagList.forEach((tag: any) => {
      const { tagNo, tagName, color, targetCount } = tag;

      //  드롭다운 메뉴
      tagOption.push(
        <Option
          // key={`exam-tag-${tagNo}`}
          value={tagNo}
          className={`exam-tag ${targetCount < 1 ? 'disabled' : ''}`}
        >
          <div className="tag-select-option flex">
            <div className={`tag-circle tag-${color}`} />
            <div className="tag-name ellipsis">{tagName}</div>
            <div className="tag-count">
              {targetCount}
              {formatMessage({
                id: 'StartExam_35',
                defaultMessage: '명',
              })}
            </div>
          </div>
        </Option>,
      );
    });

    tagListComponent = (
      <FormSelectField
        className={tagTargetCount < 1 && 'warning'}
        name="examTargetTag"
        control={control}
        error={errors.examTargetTag}
        option={tagOption}
        handleOnChange={handleSelectTag}
        mode="multiple"
        showArrow
        tagRender={(props: any) => tagRender(props, 'examTargetTag')}
        placeholder={
          tagTargetCount < 1
            ? formatMessage({ id: 'Tag_24', defaultMessage: '태그에 할당된 대상자가 없습니다.' })
            : formatMessage({ id: 'Tag_36', defaultMessage: '대상 태그를 선택하세요.' })
        }
        // dropdownClassName={`start-exam-dropdown ${tagTargetCount < 1 ? 'disabled' : ''}`}
        disabled={values.isAllTarget}
        validation={{
          validate: {
            required: (value: any) => {
              return (
                values.isAllTarget || valid.required(values.examTargetTag?.length || value?.length)
              );
            },
          },
        }}
        value={values.examTargetTag}
        filterOption={(input: any, option: any) => {
          return option.children.props.children[1].props.children
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      />
    );

    // 예외 태그 리스트
    const exceptTagList = tagData.filter(
      (tag: any) =>
        !values.exceptExamTargetTag?.includes(tag.tagNo) &&
        !values.examTargetTag?.includes(tag.tagNo),
    );

    exceptTagList.forEach((tag: any) => {
      const { tagNo, tagName, color, targetCount } = tag;
      //  드롭다운 메뉴
      exceptTagOption.push(
        <Option key={`except-tag-${tagNo}`} value={tagNo}>
          <div className="tag-select-option flex">
            <div className={`tag-circle tag-${color}`} />
            <div className="tag-name">{tagName}</div>
            <div className="tag-count">
              {targetCount}
              {formatMessage({
                id: 'StartExam_35',
                defaultMessage: '명',
              })}
            </div>
          </div>
        </Option>,
      );
    });

    exceptTagListComponent = (
      <FormSelectField
        name="exceptExamTargetTag"
        control={control}
        option={tagOption}
        handleOnChange={handleSelectTag}
        mode="multiple"
        showArrow
        tagRender={(props: any) => tagRender(props, 'exceptExamTargetTag')}
        placeholder={
          exceptTagList.length < 1
            ? formatMessage({ id: 'Tag_25', defaultMessage: '대상자 태그가 없습니다.' })
            : formatMessage({ id: 'Tag_36', defaultMessage: '대상 태그를 선택하세요.' })
        }
        // dropdownClassName={`start-exam-dropdown ${tagTargetCount < 1 ? 'disabled' : ''}`}
        disabled={values.isAllTarget}
        value={values.exceptExamTargetTag}
        filterOption={(input: any, option: any) => {
          return option.children.props.children[1].props.children
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      />
    );
  } else {
    // 등록된 태그가 없을 경우
    tagListComponent = (
      <div className="no-tag-area">
        <div className="no-tag-main-text">
          <ExclamationCircleFilled />
          {formatMessage({ id: 'Tag_25', defaultMessage: '대상자 태그가 없습니다.' })}
        </div>
        <div className="no-tag-sub-text">
          {formatMessage({
            id: 'Tag_26',
            defaultMessage:
              '대상자를 추가하고 태그를 부여하면 태그를 이용하여 여러 대상자를 한번에 선택할 수 있습니다.',
          })}
        </div>
      </div>
    );

    exceptTagListComponent = (
      <div className="no-tag-area">
        <div className="no-tag-main-text">
          <ExclamationCircleFilled />
          {formatMessage({ id: 'Tag_25', defaultMessage: '대상자 태그가 없습니다.' })}
        </div>
        <div className="no-tag-sub-text">
          {formatMessage({
            id: 'Tag_26',
            defaultMessage:
              '대상자를 추가하고 태그를 부여하면 태그를 이용하여 여러 대상자를 한번에 선택할 수 있습니다.',
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="step3-content">
      {/* 대상자 태그 선택 */}
      <div className="content-item">
        <div className="input-title">
          {formatMessage({ id: 'Tag_18', defaultMessage: '대상자 태그 선택' })}
        </div>
        {tagListComponent}
        <div className="error-message mt-5">{errorMessage}</div>
        <div>
          <Controller
            control={control}
            name="isAllTarget"
            render={({ onChange, onBlur, value, name, ref }) => (
              <Checkbox
                className="start-exam-check"
                onChange={(e) => {
                  onChange(e.target.checked);
                  setValues({ ...values, isAllTarget: e.target.checked });
                }}
                checked={values.isAllTarget}
                disabled={prepareData[0]?.targetCount < 1}
              >
                {formatMessage({
                  id: 'StartExam_47',
                  defaultMessage: '모든 대상자를 지정하여 훈련을 진행합니다.',
                })}
              </Checkbox>
            )}
          />
        </div>
      </div>

      {/* 예외 태그 선택 */}
      <div className="content-item">
        <div className="input-title">
          {formatMessage({ id: 'Tag_20', defaultMessage: '예외 태그 선택' })}
        </div>
        {exceptTagListComponent}
      </div>

      {/* 훈련 대상자 선택 비율 */}
      <div className="content-item">
        <div className="start-exam-border-box">
          <div className="header-box">
            <div className="title bold">
              {formatMessage({ id: 'StartExam_48', defaultMessage: '훈련 대상자 선택 비율' })}
              <Popover
                placement="right"
                content={parse(
                  formatMessage({
                    id: 'StartExam_46',
                    defaultMessage:
                      '선택한 대상자의 훈련 비율입니다. (예> 100명의 대상자, 훈련 비율 70% -> 70명 훈련 실시)',
                  }),
                )}
              >
                <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
              </Popover>
            </div>
          </div>
          <div className={`setting-box${tagTargetCount < 1 ? ' disabled' : ''}`}>
            <div className="setting-row">
              <div className="send-value">
                <div className="slider-area">
                  <Controller
                    control={control}
                    name="examTargetRandomPer"
                    render={({ onChange }) => (
                      <Slider
                        value={values.examTargetRandomPer}
                        tooltipVisible={false}
                        step={5}
                        onChange={(value: any) => {
                          onChange(value);
                          setValues({ ...values, examTargetRandomPer: value < 11 ? 10 : value });
                        }}
                        disabled={tagTargetCount < 1}
                      />
                    )}
                  />
                  <div className="slider-status">{`${values.examTargetRandomPer}%`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartExamStep3;
