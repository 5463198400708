import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useIntl } from 'react-intl';
import { Popover, Select, Tag } from 'antd';
import declare, {
  getDeclare,
  getDeclareMail,
  changeState,
  getExam,
  getDeclareDetail,
} from 'store/declare';
import Dimmer from 'components/common/Dimmer';
import ContentHeader from 'components/common/ContentHeader';
import TagSidebar from 'components/branch/tag/TagSidebar';
import DeclareExam from 'components/branch/declare/DeclareExam';
import DeclareMail from 'components/branch/declare/DeclareMail';
import DeclareDetail from 'components/branch/declare/DeclareDetail';
import './Declare.scss';

export const authLevel = 1;
function Declare() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { Option } = Select;

  const tagData = useSelector((state: any) => {
    return {
      data: state.tag.data,
      dataByTagNo: state.tag.dataByTagNo,
      param: state.tag.param,
      needReload: state.tag.needReload,
      selectedTag: state.tag.selectedTag,
    };
  }, shallowEqual);

  const declareData = useSelector((state: any) => {
    return {
      data: state.declare.data,
      dataByDeclareNo: state.declare.dataByDeclareNo,
      dataMail: state.declare.dataMail,
      examData: state.declare.examData,
      param: state.declare.param,
      needReload: state.declare.needReload,
      page: state.declare.page,
      totalPages: state.declare.totalPages,
      totalCount: state.declare.totalCount,
      selectedNo: state.declare.selectedNo,
      declareDetail: state.declare.declareDetail,
    };
  }, shallowEqual);

  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [selectedExamNo, setSelectedExamNo] = useState('all');

  useEffect(() => {
    // 브라우저 새로고침 시 기본 경로로 변경
    history.push('/declare/exam');

    // 훈련 드롭다운 목록 조회
    onGetExam();
  }, []);

  useEffect(() => {
    // 신고 리스트 조회
    onGetDeclare(true);
  }, [selectedExamNo]);

  // 신고 리스트 조회 - 훈련 탭
  const onGetDeclare = async (refresh = false) => {
    try {
      setLoading(true);
      const params: any = {
        tagNoArray:
          tagData.selectedTag[0]?.tagNo === 'all-tag' || tagData.selectedTag[0]?.tagNo === 'no-tag'
            ? tagData.selectedTag[0]?.tagNo
            : JSON.stringify(tagData.selectedTag.map((item: any) => item.tagNo)),
        examNoArray: selectedExamNo === 'all' ? 'all' : JSON.stringify([selectedExamNo]),
        filter: JSON.stringify(declareData.param.filter),
        sort: JSON.stringify(declareData.param.sort),
        offset: declareData.param.limit * declareData.page,
        limit: declareData.param.limit,
        multiType: 'or',
      };

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
        params.offset = 0;
      }

      await dispatch(getDeclare(params));
      setLoading(false);
    } catch (error) {
      console.log('DeclareExam onGetDeclare', error);
    }
  };

  // 신고 리스트 조회 - 메일 탭
  const onGetDeclareMail = async (refresh = false) => {
    try {
      setLoading(true);
      const params: any = {
        tagNoArray:
          tagData.selectedTag[0]?.tagNo === 'all-tag' || tagData.selectedTag[0]?.tagNo === 'no-tag'
            ? tagData.selectedTag[0]?.tagNo
            : JSON.stringify(tagData.selectedTag.map((item: any) => item.tagNo)),
        examNoArray: 'all',
        filter: JSON.stringify(declareData.param.filter),
        sort: JSON.stringify(declareData.param.sort),
        offset: declareData.param.limit * declareData.page,
        limit: declareData.param.limit,
        multiType: 'or',
      };

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
        params.offset = 0;
      }

      await dispatch(getDeclareMail(params));
      setLoading(false);
    } catch (error) {
      console.log('Declare onGetDeclare', error);
    }
  };

  // 훈련 드롭다운 리스트 조회
  const onGetExam = async () => {
    try {
      setLoading(true);

      await dispatch(getExam());

      setLoading(false);
    } catch (error) {
      console.log('DeclareExam onGetExam', error);
    }
  };

  // 신고하기 상세 조회
  const onGetDeclareDetail = async () => {
    try {
      setDetailLoading(true);

      const params: any = {
        targetNo: declareData.selectedNo,
      };

      if (selectedExamNo !== 'all') {
        params.examNoArray = selectedExamNo;
      }

      await dispatch(getDeclareDetail(params));

      setDetailLoading(false);
    } catch (error) {
      console.log('DeclareExam onGetDeclareDetail', error);
    }
  };

  // 전체 태그 선택 여부
  const isAllTag = tagData.selectedTag[0]?.tagNo === 'all-tag';

  // 선택한 태그 정보
  const currentTag = !isAllTag && tagData.dataByTagNo[tagData.selectedTag[0]?.tagNo];

  // 상단 제목
  let headerTitle = null;
  // 태그 수
  let totalTagCount = tagData.selectedTag.length;
  if (tagData.selectedTag[0]?.tagNo === 'all-tag') {
    headerTitle = formatMessage({ id: 'Tag_42', defaultMessage: '모든 대상자' });
    totalTagCount = tagData.data.length;
  } else if (tagData.selectedTag[0]?.tagNo === 'no-tag') {
    headerTitle = formatMessage({ id: 'Tag_43', defaultMessage: '태그가 없는 대상자' });
    totalTagCount = 0;
  } else {
    headerTitle =
      tagData.selectedTag.length > 1 ? (
        <Popover
          overlayClassName="between-popover"
          placement="bottomLeft"
          content={tagData.selectedTag.map((tagItem: any) => {
            const { tagNo, tagLevel } = tagItem;
            return (
              <Tag
                className={`color-tag tag-label-${tagData.dataByTagNo[tagNo].color}`}
                key={tagData.dataByTagNo[tagNo].tagNo}
              >
                {tagData.dataByTagNo[tagNo].tagName}
              </Tag>
            );
          })}
        >
          <div className="flex">
            <Tag className={`color-tag tag-label-${currentTag.color}`}>{currentTag.tagName}</Tag>
            <div>{` ${formatMessage({ id: 'Tag_51', defaultMessage: '외' })} ${tagData.selectedTag.length - 1
              }${formatMessage({ id: 'Tag_52', defaultMessage: '개 태그' })}`}</div>
          </div>
        </Popover>
      ) : (
        <Tag className={`color-tag tag-label-${currentTag.color}`}>{currentTag.tagName}</Tag>
      );
  }

  // 상단 부제목
  const headerSubTitle = `${formatMessage({ id: 'Target_12', defaultMessage: '총 대상자' })}: ${declareData.totalCount
    }${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}, ${formatMessage({
      id: 'Tag_1',
      defaultMessage: '태그',
    })}: ${totalTagCount}${formatMessage({ id: 'Exam_26', defaultMessage: '개' })}`;

  // 네이게이션 버튼 목록
  const navButtonList = useMemo(
    () => [
      {
        isShow: true,
        moveLink: '/declare/exam',
        buttonText: formatMessage({ id: 'Exam_34', defaultMessage: '훈 련' }),
        tooltipText: formatMessage({
          id: 'Exam_28',
          defaultMessage: "훈련 결과를 '훈련'별로 보여줍니다.",
        }),
      },
      {
        isShow: true,
        moveLink: '/declare/mail',
        buttonText: formatMessage({ id: 'Declare_2', defaultMessage: '메 일' }),
        tooltipText: formatMessage({
          id: 'Declare_3',
          defaultMessage: "훈련 결과를 '메일'별로 보여줍니다.",
        }),
      },
    ],
    [],
  );

  // 리스트 선택 이벤트
  const onSelectItem = (selectedNo: number) => {
    dispatch(changeState({ key: 'selectedNo', value: selectedNo }));
  };

  return (
    <div className="common-content-layout">
      <div className="common-left-side">
        <TagSidebar type="declare" />
      </div>
      <div className="common-content">
        <div className="common-inner">
          <ContentHeader
            title={headerTitle}
            subTitle={headerSubTitle}
            navButtonList={navButtonList}
            onRefresh={() =>
              pathname === '/declare/exam' ? onGetDeclare(true) : onGetDeclareMail(true)
            }
          />

          {/* 훈련 탭 리스트 */}
          {pathname === '/declare/exam' && (
            <DeclareExam
              declareData={declareData}
              loading={loading}
              selectedTag={tagData.selectedTag}
              onGetDeclare={onGetDeclare}
              onGetDeclareDetail={onGetDeclareDetail}
              onSelectItem={onSelectItem}
              changeExam={(value: any) => setSelectedExamNo(value)}
            />
          )}

          {/* 메일 탭 리스트 */}
          {pathname === '/declare/mail' && (
            <DeclareMail
              declareData={declareData}
              loading={loading}
              selectedTag={tagData.selectedTag}
              onGetDeclareMail={onGetDeclareMail}
              onSelectItem={onSelectItem}
            />
          )}
        </div>

        <div className="common-inner-right">
          {declareData.selectedNo ? (
            <DeclareDetail
              detailType={pathname === '/declare/exam' ? 'exam' : 'mail'}
              detailData={
                pathname === '/declare/exam'
                  ? declareData.declareDetail
                  : declareData.dataMail?.filter(
                    (mailData: any) => mailData.declareNo === declareData.selectedNo,
                  )
              }
              selectedNo={declareData.selectedNo}
              selectedTag={tagData.selectedTag}
              detailLoading={detailLoading}
              onGetDeclareDetail={onGetDeclareDetail}
            />
          ) : (
            <Dimmer
              dimmerText={formatMessage({
                id: 'Target_13',
                defaultMessage: '대상자를 선택하세요.',
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Declare;
