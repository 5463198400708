import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Select, Tooltip, Checkbox } from 'antd';
import './SearchFilter.scss';

// import ascendImage from 'img/filter/list_ascending.png';
// import descendImage from 'img/filter/list_descending.png';
// import selectArrowImage from 'img/filter/list_select.png';
// import searchImage from 'img/filter/list_search.png';
// import filterImage from 'img/filter/btn_filter.png';
// import filterOnImage from 'img/filter/btn_filter_active.png';

function SearchFilter(props: any) {
  const {
    filterType, // type: array, object
    sortMenu, // 정렬 드롭다운 메뉴
    filterMenu, // 필터 팝오버 메뉴
    param, // 파라미터
    paramAction, // 파라미터 변경할 액션

    dataList, // 전체 리스트 데이터(전체체크 사용 시에만 필요)
    selectedList, // 선택된 리스트(전체체크 사용 시에만 필요)
    selectedType, // select, check(전체체크 사용 시에만 필요)
    allCheckText, // 전체 체크박스에 표시할 문구(전체체크 사용 시에만 필요)
    extraBtn, // 기타 버튼 컴포넌트들
  } = props;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [visible, setVisible] = useState(false);
  const [checkFilterList, setCheckFilterList] = useState<string[]>([]);
  const [checkFilterObj, setCheckFilterObj] = useState<any>({});
  const { Option } = Select;

  useEffect(() => {
    setSearchText('');

    return (() => {
      dispatch(
        paramAction({
          key: 'param',
          value: { ...param, filter: {} },
        }),
      );
    });
  }, []);

  useEffect(() => {
    // 다른 태그 선택해도 검색 내용 유지
    const text = param.filter.searchAll || '';
    setSearchText(text);
  }, [dataList]);

  useEffect(() => {
    setCheckFilterList(Object.keys(param.filter));
    setCheckFilterObj({ ...param.filter });
  }, [param.filter]);

  // 전체 선택/해제
  const changeAllCheck = useCallback(() => {
    dispatch(
      paramAction({
        key: 'selectType',
        value: 'check',
      }),
    );

    if (dataList.length !== selectedList?.length) {
      dispatch(
        paramAction({
          key: 'selectedList',
          value: dataList,
        }),
      );
    } else {
      dispatch(
        paramAction({
          key: 'selectedList',
          value: [],
        }),
      );
    }
  }, [dataList, selectedList]);

  // 필터 체크
  const selectFilterCheck = useCallback(
    (group: any, key: any) => {
      if (filterType === 'array') {
        const newCheckList = [...checkFilterList];
        if (newCheckList.includes(key)) {
          // 체크 해제
          newCheckList.splice(newCheckList.indexOf(key), 1);
        } else {
          // 체크 표시
          newCheckList.push(key);
        }
        setCheckFilterList(newCheckList);
      } else {
        const newCheckObj: any = { ...checkFilterObj };

        // 체크 해제
        if (newCheckObj && newCheckObj[group] && newCheckObj[group].includes(key)) {
          if (newCheckObj[group].length === 1) {
            // 그룹 제거
            delete newCheckObj[group];
          } else {
            // 그룹 안의 항목 제거
            newCheckObj[group].splice(newCheckObj[group].indexOf(key), 1);
          }
        }
        // 체크 선택
        else {
          const values = newCheckObj[group] ? [...newCheckObj[group]] : [];
          values.push(key);
          newCheckObj[group] = values;
        }

        setCheckFilterObj(newCheckObj);
      }
    },
    [checkFilterList, checkFilterObj],
  );

  // 취소 버튼 클릭
  const cancelCheck = useCallback(() => {
    setVisible(false);
    if (filterType === 'array') {
      setCheckFilterList(Object.keys(param.filter));
    } else {
      setCheckFilterObj({ ...param.filter });
    }
  }, [param.filter]);

  // 검색어 입력 이벤트
  const changeSearchText = useCallback((e: any) => {
    setSearchText(e.target.value);
  }, []);

  // 필터 팝오버 현재 상태 저장
  const changeVisible = (value: boolean) => {
    setVisible(value);
    // 팝오버 닫힐 때 체크 취소 처리
    if (value === false) {
      cancelCheck();
    }
  };

  // 확인 버튼 클릭 (필터 실행)
  const onExecuteFilter = useCallback(() => {
    // 검색 필터 있을 시 넣어줌
    let filterParam: any = param.filter.searchAll ? { searchAll: param.filter.searchAll } : {};

    if (filterType === 'array') {
      if (checkFilterList.length > 0) {
        filterParam.filterType = 'or';
        checkFilterList.forEach((key: string) => {
          if (key !== 'filterType') {
            filterParam[key] = 1;
          }
        });
      }
    } else {
      filterParam = { ...filterParam, ...checkFilterObj };
    }

    setVisible(false);
    dispatch(
      paramAction({
        key: 'param',
        value: { ...param, filter: { ...filterParam } },
      }),
    );
  }, [param.filter, checkFilterList, checkFilterObj]);

  // 모든 필터 해제, 필터 해제 아이콘 클릭
  const onCancelFilter = useCallback(() => {
    if (filterType === 'array') {
      setCheckFilterList([]);
    } else {
      setCheckFilterObj({});
    }
    setSearchText('');
    if (Object.keys(param.filter).length > 0) {
      dispatch(paramAction({ key: 'param', value: { ...param, filter: {} } }));
    }
  }, [param.filter]);

  // 검색
  const onSearch = useCallback(
    (text: string) => {
      const copyparam = { ...param.filter };
      if (text) {
        copyparam.searchAll = text;
      } else {
        delete copyparam.searchAll;
      }

      dispatch(
        paramAction({
          key: 'param',
          value: { ...param, filter: copyparam },
        }),
      );
    },
    [param.filter],
  );

  // 적용된 필터 개수
  let filterCount = 0;
  Object.keys(param.filter).forEach((key: string) => {
    if (key !== 'searchAll' && key !== 'filterType') {
      if (filterType === 'array') {
        filterCount += 1;
      } else {
        filterCount += param.filter[key].length;
      }
    }
  });

  // 전체 선택 여부
  const isAll = dataList?.length === selectedList?.length;

  // 리스트 정렬 변경
  const changeSort = useCallback(
    (value: any) => {
      const sortParam = [...param.sort];
      if (value) {
        // 위험 대상자순, 발송순, ...
        sortParam[0].field = value;
      } else {
        // 오름차순, 내림차순 변경
        sortParam[0].order = sortParam[0].order === 'DESC' ? 'ASC' : 'DESC';
      }

      dispatch(
        paramAction({
          key: 'param',
          value: { ...param, sort: sortParam },
        }),
      );
    },
    [param.sort],
  );

  let allCheckComponent = <Checkbox className="filter-check" checked={false} />;
  if (
    selectedList?.length > 0 &&
    selectedList?.length < dataList?.length &&
    selectedType === 'check'
  ) {
    allCheckComponent = (
      <img src="/img/exam/list_check_each.png" alt="check" className="filter-check image" />
    );
  } else if (isAll && selectedType === 'check') {
    allCheckComponent = (
      <img src="/img/exam/list_check_on.png" alt="check" className="filter-check image" />
    );
  }

  return (
    <div className="search-filter-wrap">
      <div className="filter-left-area">
        {/* 전체 체크 */}
        {selectedType && (
          <div
            className="all-checkbox-area round-grey-button"
            onClick={changeAllCheck}
            aria-hidden="true"
          >
            {allCheckComponent}

            {/* <Checkbox
              className="filter-check"
              checked={isAll && selectedType === 'check'}
              indeterminate={
                selectedList?.length > 0 &&
                selectedList?.length < dataList.length &&
                selectedType === 'check'
              }
            /> */}

            <div className="check-label">
              {isAll ? allCheckText.noneText : allCheckText.allText}
            </div>
          </div>
        )}
        {/* 기타 버튼 */}
        <div className="extra-buttons">{extraBtn}</div>
      </div>

      <div className="filter-right-area">
        {/* 오름차순/내림차순 */}
        {sortMenu && (
          <Tooltip
            overlayClassName="black-tooltip"
            placement="top"
            title={
              param?.sort && param.sort[0].order === 'DESC'
                ? formatMessage({ id: 'Filter_10', defaultMessage: '오름차순으로 변경' })
                : formatMessage({ id: 'Filter_11', defaultMessage: '내림차순으로 변경' })
            }
          >
            <div className="sort-icon" onClick={() => changeSort('')} aria-hidden="true">
              {param?.sort && param.sort[0].order === 'DESC' ? (
                <img src="/img/filter/list_descending.png" alt="descend" />
              ) : (
                <img src="/img/filter/list_ascending.png" alt="ascend" />
              )}
            </div>
          </Tooltip>
        )}

        {/* 정렬 */}
        {sortMenu && (
          <div className="filter-item">
            <Tooltip
              overlayClassName="black-tooltip"
              placement="top"
              title={formatMessage({ id: 'Filter_13', defaultMessage: '정렬 방법 선택' })}
            >
              <Select
                className="filter-select"
                dropdownClassName="filter-dropdown"
                placeholder={formatMessage({ id: 'Filter_12', defaultMessage: '정렬' })}
                onChange={changeSort}
                suffixIcon={<img src="/img/filter/list_select.png" alt="arrow" />}
              >
                {Object.keys(sortMenu).map((key: any) => {
                  return (
                    <Option value={key} key={key}>
                      {sortMenu[key]}
                    </Option>
                  );
                })}
              </Select>
            </Tooltip>
          </div>
        )}

        {!!filterMenu && (
          <div className="filter-item">
            <Popover
              overlayClassName={`filter-popover ${
                Object.keys(filterMenu).length < 2 ? 'single' : ''
              }`}
              placement="bottom"
              content={
                <>
                  <div className="filter-popover-content">
                    {Object.keys(filterMenu).map((key: any) => (
                      <div className="filter-popover-item" key={key}>
                        <div className="filter-popover-title">{filterMenu[key].name}</div>
                        {filterMenu[key].child?.map((item: any) => (
                          <div
                            className="filter-popover-row"
                            key={item.value}
                            onClick={() => selectFilterCheck(key, item.value)}
                            aria-hidden="true"
                          >
                            <Checkbox
                              className="filter-check"
                              checked={
                                filterType === 'array'
                                  ? checkFilterList.includes(item.value)
                                  : checkFilterObj[key]?.includes(item.value)
                              }
                            />
                            <div className="filter-popover-text">{item.label}</div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="filter-popover-footer">
                    <div
                      className="filter-footer-reset"
                      onClick={onCancelFilter}
                      aria-hidden="true"
                    >
                      {formatMessage({ id: 'Filter_7', defaultMessage: '모든 필터 해제' })}
                    </div>
                    <div className="filter-footer-btn-area">
                      <button className="footer-btn" type="button" onClick={cancelCheck}>
                        {formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
                      </button>
                      <button className="footer-btn ok" type="button" onClick={onExecuteFilter}>
                        {formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
                      </button>
                    </div>
                  </div>
                </>
              }
              trigger="click"
              visible={visible}
              onVisibleChange={changeVisible}
            >
              <div className={`filter-area ${filterCount > 0 ? 'active' : ''}`}>
                <div className="filter-text">
                  {formatMessage({ id: 'Filter_1', defaultMessage: '필터' })}
                </div>
                {filterCount > 0 ? (
                  <div className="number-box">{filterCount}</div>
                ) : (
                  <img src="/img/filter/list_select.png" alt="arrow" />
                )}
              </div>
            </Popover>
          </div>
        )}

        {/* 검색 */}
        <div className="filter-item">
          <div className="filter-input">
            <input
              className={`filter-search-input ${searchText ? 'active' : ''}`}
              type="text"
              placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
              value={searchText}
              onChange={changeSearchText}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') onSearch(searchText);
              }}
            />
            <div className="search-icon" onClick={() => onSearch(searchText)} aria-hidden="true">
              <img src="/img/filter/list_search.png" alt="search" />
            </div>
          </div>
        </div>

        {/* 필터 해제 */}
        <div className="filter-item">
          <Tooltip
            overlayClassName="black-tooltip"
            placement="top"
            title={formatMessage({ id: 'Filter_6', defaultMessage: '필터 해제' })}
          >
            <div className="filter-cancel-icon" onClick={onCancelFilter} aria-hidden="true">
              {Object.keys(param.filter).length > 0 ? (
                <img src="/img/filter/btn_filter_active.png" alt="cancel" />
              ) : (
                <img src="/img/filter/btn_filter.png" alt="cancel" />
              )}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
