import { SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getEduTemplate, changeParam } from 'store/edu';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import TableDatePicker from 'components/common/TableDatePicker';
import EduTemplateSettings from 'components/branch/eduTemplate/EduTemplateSettings';
import { Tag } from 'antd';
import { setInitial } from 'store/edu';

export default function EduBasicTemplateList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.edu.param.sort,
      filter: state.edu.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.edu.needReload);
  const respData = useSelector((state: any) => state.edu.data);
  const totalCount = useSelector((state: any) => state.edu.totalCount);
  const totalPages = useSelector((state: any) => state.edu.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    getEduTemplateData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getEduTemplateData = async () => {
    if (!loading) {
      setLoading(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
          isBasic: 1,
        };

        await dispatch(getEduTemplate(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoading(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    eduAttachNo: 'No',
    attachName: formatMessage({ id: 'Edu_5', defaultMessage: '컨텐츠명' }),
  };

  // 필터
  const filterOption: { [key: string]: any; } = {};

  // 테이블
  const baseColumns = [
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 130,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any; }) {
        return (
          <EduTemplateSettings
            isAdmin={isAdmin}
            dataInfo={props.row.original}
            templateType="basic"
          />
        );
      },
    },
    {
      Header: 'No',
      accessor: 'eduAttachNo',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any; }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    // {
    //   Header: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
    //   accessor: 'serviceNo',
    //   resizable: false,
    //   sortable: true,
    //   width: 80,
    //   headerClassName: 'react-table-th',
    //   className: 'react-table-td text-align-center',
    //   Cell: ({ value }: any) => {
    //     let printValue = null;
    //     if (value) {
    //       printValue = (
    //         <Tag className="temp-type-label color-temp-user no-margin">
    //           {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
    //         </Tag>
    //       );
    //     } else {
    //       printValue = (
    //         <Tag className="temp-type-label color-temp-system no-margin">
    //           {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
    //         </Tag>
    //       );
    //     }

    //     return printValue;
    //   },
    // },
    {
      Header: formatMessage({ id: 'Edu_82', defaultMessage: '컨텐츠 유형' }),
      accessor: 'contentType',
      resizable: false,
      sortable: true,
      width: 100,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: ({ value }: any) => {
        let printValue = null;
        if (value === 1 || value === 4) {
          printValue = <Tag className="color-border-down">MP4</Tag>;
        } else if (value === 2) {
          printValue = <Tag className="color-border-red">YouTube</Tag>;
        } else if (value === 3) {
          printValue = <Tag className="color-border-cure">PDF</Tag>;
        }

        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Edu_5', defaultMessage: '컨텐츠명' }),
      accessor: 'attachName',
      resizable: true,
      sortable: true,
      width: 500,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Edu_7', defaultMessage: '문제 개수' }),
      accessor: 'quizCount',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: ({ value }: any) => {
        const printValue = value || '-'

        return printValue;
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 150,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any; }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  if (isAdmin) {
    baseColumns.splice(5, 0, {
      Header: formatMessage({ id: 'Template_26', defaultMessage: '공개' }),
      accessor: 'isPublic',
      sortable: true,
      resizable: false,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any; }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = 'O';
        }
        return printValue;
      },
    });
  }

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
        disableKey="isEnable"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
