import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';
import { Tag } from 'antd';
import { timeFormatFromUTCEpoch, timeDiffFromSeconds } from 'utils/commonFunctions';
import { CheckSquareFilled } from '@ant-design/icons';

// import noImage from 'img/edu/thumbnail_noimg.png';
// import wrapImage from 'img/edu/thumbnail_wrap.png';

interface eduDetailProps {
  detailType: string;
  detailData: any;
  eduSummaryData: any;
}

function EduDetail({ detailType, detailData, eduSummaryData }: eduDetailProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // 영상 총 재생시간
  let runningTime = 0;
  const eduContentsList: any = [];
  if (detailData?.fileInfo) {
    detailData?.fileInfo.forEach((file: any, index: number) => {
      runningTime += file.runningTime;

      const eduQuizList: any = [];
      if (Array.isArray(detailData?.quizData)) {
        // 퀴즈 리스트
        const quizList = detailData?.quizData?.filter((quiz: any) => quiz.fileNo === file.fileNo);
        quizList.forEach((quiz: any, index: number) => {
          eduQuizList.push(
            <div className="quiz-list" key={quiz.quizNo}>{`Q${index + 1}. ${
              quiz.quizContent
            }`}</div>,
          );
        });
      }

      // 컨텐츠 리스트
      eduContentsList.push(
        <div key={file.fileNo}>
          {`${index + 1}. ${file.fileName}`}
          <span className="grey-text">{`(${timeDiffFromSeconds(file.runningTime)})`}</span>
          {eduQuizList}
        </div>,
      );
    });
  }

  // 교육 영상 타입별 정보
  const videoType: any = {};
  if (detailData?.contentType === 2) {
    // youtube thumbnail 추출
    const url = new URL(detailData?.fileInfo[0]?.filePath);
    const youtubeId =
      url?.pathname?.indexOf('watch') > -1
        ? new URLSearchParams(url?.search)?.get('v')
        : url?.pathname?.split('/').pop();

    videoType['2'] = {
      type: (
        <Tag className="color-border-red" key="YouTube">
          YouTube
        </Tag>
      ),
      thumbnail: `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`,
    };
  } else {
    videoType['1'] = {
      type: (
        <Tag className="color-border-down" key="MP4">
          MP4
        </Tag>
      ),
      thumbnail: detailData?.thumbNail
        ? `/fdata${detailData?.thumbNail}`
        : '/img/edu/thumbnail_noimg.png',
    };
  }

  // 교육 컨텐츠
  // const eduContents = (
  //   <div>
  //     <div>
  //       <span className="bold">{detailData?.attachName}</span>
  //       <span className="grey-text">{`(${timeDiffFromSeconds(runningTime)})`}</span>
  //     </div>
  //     <div className="edu-contents-list">{eduContentsList}</div>
  //     <div className="edu-thumbnail">
  //       <img
  //         className="thumbnail"
  //         src={videoType[detailData?.contentType]?.thumbnail}
  //         alt="thumb"
  //       />
  //       <div className="edu-thumbnail-wrap">
  //         <img src="/img/edu/thumbnail_wrap.png" alt="wrapImg" />
  //       </div>
  //     </div>
  //   </div>
  // );

  // 교육 현황 차트 데이터
  const circleChartArray = [
    {
      name: '',
      color: '',
      value: detailData?.eduCount > 0 ? 0 : 100,
      label: '',
      percent: detailData?.eduCount > 0 ? 0 : 100,
    },
    {
      name: 'beforeCount',
      color: '#c8c8c8',
      value: detailData?.beforeCount,
      label: formatMessage({ id: 'Status_15', defaultMessage: '진행전' }),
      percent: ((detailData?.beforeCount / detailData?.eduCount) * 100).toFixed(0),
    },
    {
      name: 'playCount',
      color: '#ffcb2d',
      value: detailData?.playCount,
      label: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
      percent: ((detailData?.playCount / detailData?.eduCount) * 100).toFixed(0),
    },
    {
      name: 'afterCount',
      color: '#ff6643',
      value: detailData?.afterCount,
      label: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
      percent: ((detailData?.afterCount / detailData?.eduCount) * 100).toFixed(0),
    },
  ];

  // 교육 현황
  const eduStatusChart = (
    <div className="chart-item exam-detail">
      <PieChart width={340} height={150} margin={{ top: 20, right: 10, bottom: 20, left: 10 }}>
        <Pie
          dataKey="value"
          data={[
            {
              name: '',
              value: 100,
            },
          ]}
          cx={55}
          cy={55}
          paddingAngle={0}
          innerRadius={20}
          outerRadius={55}
          fill="#f7f7f7"
        />
        <Pie
          dataKey="value"
          data={circleChartArray}
          cx={55}
          cy={55}
          innerRadius={25}
          outerRadius={50}
          startAngle={450}
          endAngle={90}
          paddingAngle={-1}
          activeIndex={0}
          activeShape={(e: any) => {
            const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = e;
            return (
              <g>
                {/* 진행전 */}
                <defs>
                  <linearGradient id="c8c8c8" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#e3e3e3" />
                    <stop offset="50%" stopColor="#e3e3e3" />
                    <stop offset="100%" stopColor="#bababa" />
                  </linearGradient>
                </defs>
                {/* 진행중 */}
                <defs>
                  <linearGradient id="ffcb2d" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#f2af0e" />
                    <stop offset="50%" stopColor="#f2af0e" />
                    <stop offset="100%" stopColor="#ff6643" />
                  </linearGradient>
                </defs>
                {/* 완료 */}
                <defs>
                  <linearGradient id="ff6643" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#e14b16" />
                    <stop offset="50%" stopColor="#e14b16" />
                    <stop offset="100%" stopColor="#ff6643" />
                  </linearGradient>
                </defs>
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill={fill}
                />
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={outerRadius - 1}
                  outerRadius={outerRadius + 8}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill={fill}
                />
              </g>
            );
          }}
        >
          {/* 퍼센트 표시 */}
          {circleChartArray.map((entry: any) => (
            <Cell key={entry.name} fill={`url(${entry.color})`} />
          ))}
        </Pie>
        <Legend
          iconSize={10}
          width={190}
          layout="vertical"
          align="right"
          wrapperStyle={{
            top: 15,
            left: 150,
            lineHeight: '30px',
          }}
          content={({ payload }: any) => {
            const legendItem = payload.filter(
              (item: any) =>
                item.value === 'beforeCount' ||
                item.value === 'playCount' ||
                item.value === 'afterCount',
            );
            return (
              <div className="chart-legend">
                <div className="flex bold">
                  <div className="total-message">
                    {formatMessage({ id: 'Target_12', defaultMessage: '총 대상자' })}
                  </div>
                  <div className="value">{`${detailData?.eduCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                {legendItem.length > 0 &&
                  legendItem.map((data: any) => {
                    const { color, label, value, percent } = data.payload.payload;

                    return (
                      <div className="flex" key={data.name}>
                        <div className="color" style={{ backgroundColor: color }} />
                        <div className="message">{label}</div>
                        <div className="value">{`${value}${formatMessage({
                          id: 'StartExam_35',
                          defaultMessage: '명',
                        })} (${percent}%)`}</div>
                      </div>
                    );
                  })}
              </div>
            );
          }}
        />
      </PieChart>
    </div>
  );

  // 교육 정보
  // const eduInfo = [
  //   {
  //     title: formatMessage({ id: 'Edu_27', defaultMessage: '교육 정보' }),
  //     contents: [
  //       {
  //         text: 'No',
  //         value: detailData?.eduNo,
  //       },
  //       {
  //         text: formatMessage({ id: 'Edu_28', defaultMessage: '교육명' }),
  //         value: detailData?.eduName,
  //       },
  //       {
  //         text: formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' }),
  //         value: `${timeFormatFromUTCEpoch(detailData?.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
  //           detailData?.endEpoch,
  //           3,
  //         )}`,
  //       },
  //       {
  //         text: formatMessage({ id: 'Edu_82', defaultMessage: '컨텐츠 유형' }),
  //         value: videoType[detailData?.contentType].type,
  //       },
  //       {
  //         text: formatMessage({ id: 'Edu_10', defaultMessage: '교육 컨텐츠' }),
  //         value: eduContents,
  //       },
  //       {
  //         text: formatMessage({ id: 'Edu_29', defaultMessage: '교육 현황' }),
  //         value: eduStatusChart,
  //       },
  //       {
  //         text: formatMessage({ id: 'Date_3', defaultMessage: '등록일시' }),
  //         value: timeFormatFromUTCEpoch(detailData?.regEpoch),
  //       },
  //     ],
  //   },
  // ];
  const eduInfo: Array<any> = useMemo(
    () => [
      { text: 'No', value: detailData?.eduNo },
      {
        text: formatMessage({ id: 'Edu_28', defaultMessage: '교육명' }),
        value: detailData?.eduName,
      },
      {
        text: formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' }),
        value: `${timeFormatFromUTCEpoch(detailData?.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
          detailData?.endEpoch,
          3,
        )}`,
      },
      {
        text: formatMessage({ id: 'Edu_113', defaultMessage: '교육 유형' }),
        value: detailData?.attachInfo?.map((attach: any) => {
          let contentTag = null;
          if (attach.contentType === 1 || attach.contentType === 4) {
            contentTag = (
              <Tag key={attach.eduAttachNo} className="color-border-down">
                MP4
              </Tag>
            );
          } else if (attach.contentType === 2) {
            contentTag = (
              <Tag key={attach.eduAttachNo} className="color-border-red">
                YouTube
              </Tag>
            );
          } else if (attach.contentType === 3) {
            contentTag = (
              <Tag key={attach.eduAttachNo} className="color-border-cure">
                PDF
              </Tag>
            );
          }

          return contentTag;
        }),
      },
      {
        text: formatMessage({ id: 'Edu_4', defaultMessage: '교육 템플릿' }),
        value: (
          <div>
            {detailData?.attachInfo?.map((attach: any) => (
              <div key={attach.eduAttachNo}>{attach.attachName}</div>
            ))}
          </div>
        ),
      },
      { text: formatMessage({ id: 'Edu_29', defaultMessage: '교육 현황' }), value: eduStatusChart },
      {
        text: formatMessage({ id: 'Date_3', defaultMessage: '등록일시' }),
        value: timeFormatFromUTCEpoch(detailData?.regEpoch),
      },
    ],
    [detailData],
  );

  let eduStatusText = formatMessage({ id: 'Status_15', defaultMessage: '진행전' });
  if (detailData?.eduStatus === 1) {
    eduStatusText = formatMessage({ id: 'Status_8', defaultMessage: '진행중' });
  } else if (detailData?.eduStatus === 2) {
    eduStatusText = formatMessage({ id: 'Status_16', defaultMessage: '완료' });
  }

  // 대상자 교육 결과
  const targetInfo = [
    {
      title: formatMessage({ id: 'Edu_30', defaultMessage: '대상자 교육 결과' }),
      contents: [
        {
          text: formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' }),
          value: detailData?.targetNo,
        },
        {
          text: formatMessage({ id: 'Name_5', defaultMessage: '대상자 이름' }),
          value: detailData?.targetName,
        },
        {
          text: formatMessage({ id: 'Email_24', defaultMessage: '대상자 메일' }),
          value: detailData?.targetEmail,
        },
        {
          text: formatMessage({ id: 'Division_3', defaultMessage: '대상자 소속' }),
          value: detailData?.targetDivision || '-',
        },
        {
          text: formatMessage({ id: 'Position_3', defaultMessage: '대상자 직급' }),
          value: detailData?.targetPosition || '-',
        },
        {
          text: formatMessage({ id: 'Send_1', defaultMessage: '발송' }),
          value: timeFormatFromUTCEpoch(detailData?.send),
        },
        {
          text: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
          value: timeFormatFromUTCEpoch(detailData?.read),
        },
        {
          text: formatMessage({ id: 'Edu_29', defaultMessage: '교육 현황' }),
          value: eduStatusText,
        },
        {
          text: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
          value: timeFormatFromUTCEpoch(detailData?.finish),
        },
      ],
    },
  ];

  return (
    <div className="detail-area edu-detail">
      {/* 교육 상세 */}
      {detailType === 'edu' && (
        <>
          {/* <div className="detail-item" key={data.text}>
          <div className="detail-title">{data.title}</div> */}
          {/* 교육 정보 */}
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Edu_27', defaultMessage: '교육 정보' })}
            </div>
            <div className="detail-contents">
              {eduInfo.map((content: any) => (
                <div className="detail-row" key={content.text}>
                  <div className="detail-text">{content.text}</div>
                  <div className="detail-value">{content.value}</div>
                </div>
              ))}
            </div>
          </div>

          {/* 태그 */}
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}
            </div>
            <div className="detail-contents">
              {detailData?.eduTagData?.length &&
                detailData?.eduTagData.map((tagData: any) => {
                  return (
                    <Tag className={`color-tag tag-label-${tagData.color}`} key={tagData.tagNo}>
                      {tagData.tagName}
                    </Tag>
                  );
                })}
            </div>
          </div>

          {/* 훈련 */}
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Edu_119', defaultMessage: '훈련' })}
            </div>
            <div className="detail-contents">
              <div className="inner-item">
                {detailData?.examInfo?.length ? (
                  detailData?.examInfo.map((examData: any) => (
                    <div className="inner-contents" key={examData.examNo}>
                      <div className="inner-row">
                        <div className="dot-title">
                          <div className="dot">·</div>
                          <span className="bold font-size-14">{examData.examName}</span>
                        </div>
                      </div>
                      <div className="inner-row">
                        {formatMessage({
                          id: 'Period_3',
                          defaultMessage: '훈련 기간',
                        })}

                        <div className="row-grey">{`${timeFormatFromUTCEpoch(
                          examData.startEpoch,
                          3,
                        )} ~ ${timeFormatFromUTCEpoch(examData.endEpoch, 3)}`}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data color-brand-green">
                    <CheckSquareFilled />
                    {formatMessage({ id: 'Exam_71', defaultMessage: '연결된 훈련 없음' })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* 교육 대상자 상세 */}
      {detailType === 'target' && (
        <>
          {/* 대상자 교육 결과 */}
          {targetInfo.map((data: any) => (
            <div className="detail-item" key={data.title}>
              <div className="detail-title">{data.title}</div>
              <div className="detail-contents">
                {data.contents.map((content: any) => (
                  <div className="detail-row" key={content.text}>
                    <div className="detail-text">{content.text}</div>
                    <div className="detail-value">{content.value}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* 대상자 퀴즈 결과 */}
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Edu_31', defaultMessage: '대상자 퀴즈 결과' })}
            </div>
            <div className="detail-contents">
              <div className="inner-item">
                {eduSummaryData?.quizData?.length > 0 ? (
                  <table className="quiz-table">
                    <tbody>
                      <tr className="quiz-table-header">
                        <th>{formatMessage({ id: 'Edu_126', defaultMessage: '퀴즈 내용' })}</th>
                        <th className="answer">
                          {formatMessage({ id: 'Edu_34', defaultMessage: '정답 여부' })}
                        </th>
                      </tr>
                      {eduSummaryData?.quizData?.map((quiz: any) => {
                        const solutionData = detailData?.quizSolutionData?.[quiz.quizNo];
                        let solution = formatMessage({ id: 'Edu_37', defaultMessage: '미제출' });
                        if (solutionData) {
                          solution =
                            solutionData.result === 'pass'
                              ? formatMessage({ id: 'Edu_35', defaultMessage: '정답' })
                              : formatMessage({ id: 'Edu_36', defaultMessage: '오답' });
                        }
                        return (
                          <tr className="quiz-table-content" key={quiz.quizNo}>
                            <td>{quiz.quizContent}</td>
                            <td className="answer">{solution}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data color-brand-green">
                    <CheckSquareFilled />
                    {formatMessage({ id: 'Edu_32', defaultMessage: '퀴즈 결과 없음' })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EduDetail;
